import { Box, Grid, TextField, makeStyles, Button } from '@material-ui/core'
import clsx from 'clsx'

import { useForm, Controller } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'
import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import * as service from 'service'
import styles from './styles'
import helpers from 'helpers'
const useStyles = makeStyles(styles)

const ReportsForm = () => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  const { control, handleSubmit, errors } = useForm({})

  const onSubmit = async (data) => {
    try {
      const response = await service.intermediador.reports.financial({
        sellerId: data?.sellerId || '',
        sellerName: data?.sellerName || '',
        approvalDateGt: helpers.formatters.date(data?.approvalDateGt) || '',
        approvalDateLt: helpers.formatters.date(data?.approvalDateLt) || '',
        createdAtGt: helpers.formatters.date(data?.createdAtGt) || '',
        createdAtLt: helpers.formatters.date(data?.createdAtLt) || '',
        perPage: 1_000_000,
      })

      const lines = response.data.split('\n')

      const csvData = []

      for (const line of lines) {
        const values = line.split(',')
        csvData.push(values)
      }

      const csvContent = csvData.map((row) => row.join(',')).join('\n')
      const blob = new Blob([csvContent], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'financeiro.csv'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)

      history.push(routes.reports.main)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          `Falha ao exportar relatório.`,
        variant: 'error',
      })
    }
  }

  return (
    <>
      <form id="reportsForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              as={
                <TextField
                  label="Id do vendedor"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.sellerId}
                  className={classes.roundedTextField}
                  helperText={errors?.sellerId?.message}
                  fullWidth
                />
              }
              control={control}
              name="sellerId"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <TextField
                  label="Nome do vendedor"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.sellerName}
                  className={classes.roundedTextField}
                  helperText={errors?.sellerName?.message}
                  fullWidth
                />
              }
              control={control}
              name="sellerName"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <DatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data de aprovação inicial"
                  helperText={errors?.paymentsApprovalDate?.message}
                  error={!!errors.paymentsApprovalDate}
                  inputVariant="outlined"
                  disableFuture
                  clearable
                  className={classes.roundedTextField}
                />
              }
              control={control}
              name="approvalDateGt"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <DatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data de aprovação final"
                  helperText={errors?.paymentsApprovalDate?.message}
                  error={!!errors.paymentsApprovalDate}
                  inputVariant="outlined"
                  clearable
                  disableFuture
                  className={classes.roundedTextField}
                />
              }
              control={control}
              name="approvalDateLt"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <DatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data de criação inicial"
                  helperText={errors?.createdAt?.message}
                  error={!!errors.createdAt}
                  inputVariant="outlined"
                  disableFuture
                  clearable
                  className={classes.roundedTextField}
                />
              }
              control={control}
              name="createdAtGt"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <DatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data de criação final"
                  helperText={errors?.createdAt?.message}
                  error={!!errors.createdAt}
                  inputVariant="outlined"
                  disableFuture
                  className={classes.roundedTextField}
                  clearable
                />
              }
              control={control}
              name="createdAtLt"
              mode="onBlur"
            />
          </Grid>
        </Grid>
      </form>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={1}>
          <Button
            type="button"
            variant="outlined"
            className={clsx(classes.backButton, classes.roundedButton)}
            onClick={() => history.push(routes.reports.main)}
          >
            Voltar
          </Button>
        </Box>
        <Box>
          <Button
            form="reportsForm"
            color="primary"
            variant="contained"
            type="submit"
            className={classes.roundedButton}
          >
            Download
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default ReportsForm
