import {
  Box,
  TablePagination,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'

import {
  Container,
  ContentHeader,
  Page,
  Filters,
  FilterButton,
  LoadingButton,
} from 'components'
import { AccountsTable } from './components'
import { useState } from 'react'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'
import { useHistory } from 'react-router-dom'

import theme from 'theme'
import styles from './styles'
import { routes } from 'Routes'

import * as service from 'service'

const useStyles = makeStyles(styles)

const AccountsMain = () => {
  const filter = useFilter()
  const classes = useStyles()
  const [refresh, setRefresh] = useState(0)
  const history = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { page, perPage, handleChangePage } = usePagination(10)

  const { response, isLoading } = useFetch(
    service.intermediador.accounts.get,
    { ...{ ...filter.filters }, page, perPage },
    [page, perPage, filter.filters],
  )

  return (
    <Page className={classes.root} title="Clientes">
      <Container maxWidth={false}>
        <ContentHeader menu="Clientes" title="Listagem" subtitle="Clientes" />
        <Box mt={4} display="flex" justifyContent="end">
          <Box mr={2}>
            <LoadingButton
              variant="contained"
              color="primary"
              text="Criar"
              onClick={() => history.push(routes.accounts.new)}
            />
          </Box>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </Box>
        <AccountsTable
          accounts={response?.data?.accounts}
          isLoading={isLoading}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <Box mt={2} display="flex" justifyContent="flex-end">
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onChangePage={handleChangePage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
        <Filters filter={filter}>
          <input textfieldinput="true" label="ID" name="id" />
          <input textfieldinput="true" label="Nome" name="name" />
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            disableFuture
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            disableFuture
            data-target="createdAtGt"
            data-max
          />
        </Filters>
      </Container>
    </Page>
  )
}

export default AccountsMain
