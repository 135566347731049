import { AppBar, Toolbar, Button, makeStyles, Box } from '@material-ui/core'

import styles from './styles'
import Logo from 'images/logo-horizontal-y.png'

const useStyles = makeStyles(styles)

const TopBar = ({ loginWithButton = () => {}, signUpButton = () => {} }) => {
  const classes = useStyles()

  return (
    <AppBar elevation={2} className={classes.appBar}>
      <Toolbar>
        <Box className={classes.logo}>
          <img src={Logo} alt="logo-horizontal" className={classes.logo} />
        </Box>
        <Box display="flex">
          <Box ml={2}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={loginWithButton}
            >
              Acessar
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
