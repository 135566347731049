const SUBTRACTION_CONDITION = 'SUBTRACTION'
const PERCENTAGE_CONDITION = 'PERCENTAGE'

const SUBTRACTION_LABEL = 'SUBTRAÇÃO'
const PERCENTAGE_LABEL = 'PORCENTAGEM'

const MASTERCARD_ID = 1
const VISA_ID = 2
const AMEX_ID = 3
const ELO_ID = 4
const HIPERCARD_ID = 5
const BILLET_ID = 6
const PIX_ID = 7

const MASTERCARD_LABEL = 'Mastercard'
const VISA_LABEL = 'Visa'
const AMEX_LABEL = 'Amex'
const ELO_LABEL = 'Elo'
const HIPERCARD_LABEL = 'Hipercard'
const BILLET_LABEL = 'Boleto'
const PIX_LABEL = 'Pix'

const SELECT_CONDITION = [
  { id: SUBTRACTION_CONDITION, name: SUBTRACTION_LABEL },
  { id: PERCENTAGE_CONDITION, name: PERCENTAGE_LABEL },
]

const SPLITS = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 },
  { id: 12, name: 12 },
]

const paymentMethodPriceTables = {
  SUBTRACTION_CONDITION,
  PERCENTAGE_CONDITION,
  SUBTRACTION_LABEL,
  PERCENTAGE_LABEL,
  SELECT_CONDITION,
  SPLITS,
  MASTERCARD_ID,
  VISA_ID,
  AMEX_ID,
  ELO_ID,
  HIPERCARD_ID,
  BILLET_ID,
  PIX_ID,
  MASTERCARD_LABEL,
  VISA_LABEL,
  AMEX_LABEL,
  ELO_LABEL,
  HIPERCARD_LABEL,
  BILLET_LABEL,
  PIX_LABEL,
}

export default paymentMethodPriceTables
