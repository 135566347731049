import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const approve = async ({ paymentId = '', ...params }) => {
  return await intermediadorAPI.put(`/payments/${paymentId}/approve`, params)
}

const payments = {
  approve,
}

export default payments
