const cnpjInput = (cnpj) => {
  if (cnpj === null || cnpj === undefined) return ''

  let l = cnpj.length
  if (l <= 3) return cnpj

  if (l > 20) return cnpj.slice(0, 20)

  cnpj = cnpj.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '')

  l = cnpj.length

  if (l <= 5) return cnpj.replace(/^(\d{2})(\d{1,3})*/, '$1.$2')
  if (l <= 8) return cnpj.replace(/^(\d{2})(\d{3})(\d{1,3}).*/, '$1.$2.$3')
  if (l < 13)
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{1,4}).*/, '$1.$2.$3/$4')
  if (l < 15)
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2}).*/,
      '$1.$2.$3/$4-$5',
    )

  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
    '$1.$2.$3/$4-$5',
  )
}

export default cnpjInput
