import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  TextField,
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  LoadingFeedback,
  BackButton,
  Select as SelectComponent,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import styles from './styles'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const PriceTablesForm = ({ isEdit, priceTable }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [showAnticipationField, setShowAnticipationField] = useState(
    priceTable?.antecipation || false,
  )
  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema(isEdit),
    defaultValues: {
      name: priceTable?.name || '',
      description: priceTable?.description || '',
      oldTableId: priceTable?.oldTableId || '',
      status: priceTable?.status || '',
      antecipation: priceTable?.antecipation || '',
      antecipationDays: priceTable?.antecipationDays || '',
    },
  })

  const onSubmit = async (data) => {
    if (showAnticipationField) {
      data.antecipation = true
    } else {
      data.antecipation = false
    }

    if (!showAnticipationField) {
      data.antecipationDays = null
    }

    try {
      setLoading(true)
      if (isEdit) {
        await service.intermediador.priceTables.edit({
          priceTableId: priceTable?.id,
          priceTable: {
            status: data?.status,
            description: data?.description,
            antecipation: data?.antecipation,
            antecipationDays: data?.antecipationDays,
          },
        })
      } else {
        await service.intermediador.priceTables.create({
          priceTable: {
            name: data?.name,
            description: data?.description,
            oldTableToken: data?.old_table_id,
            antecipation: data?.antecipation,
            antecipationDays: data?.antecipationDays,
          },
        })
      }
      snackbar.open({
        message: `Tabela de preço ${
          isEdit ? 'atualizada' : 'criada'
        }  com sucesso`,
        variant: 'success',
      })
      setLoading(false)
      history.push(routes.priceTables.main)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          `Falha ao ${isEdit ? 'atualizar' : 'criar'} a tabela de preço!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const handleCheckboxChange = (event) => {
    setShowAnticipationField(event.target.checked)
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Box py={1}>
        <form id="priceTableForm" onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ gap: 20 }}>
            {!isEdit && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      className={classes.roundedTextField}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Descrição"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.description}
                    className={classes.roundedTextField}
                    helperText={errors?.description?.message}
                    fullWidth
                  />
                }
                control={control}
                name="description"
                mode="onBlur"
              />
            </Grid>
            {!isEdit && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Token da tabela antiga(se tiver)"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.oldTableId}
                      className={classes.roundedTextField}
                      helperText={errors?.oldTableId?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="old_table_id"
                  mode="onBlur"
                />
              </Grid>
            )}
            {isEdit && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <SelectComponent
                      label="Status"
                      items={constants.priceTables.SELECT_STATUS}
                      error={!!errors.status}
                      className={classes.roundedTextField}
                      helperText={errors?.status?.message}
                    />
                  }
                  control={control}
                  name="status"
                  mode="onBlur"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={showAnticipationField}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Antecipação de recebimento"
                name="antecipation"
              />
            </Grid>
            {showAnticipationField && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Dias de antecipação"
                      type="number"
                      color="primary"
                      variant="outlined"
                      error={!!errors.antecipationDays}
                      className={classes.roundedTextField}
                      helperText={errors?.antecipationDays?.message}
                      fullWidth
                      inputProps={{ min: 1 }}
                      required
                    />
                  }
                  control={control}
                  name="antecipationDays"
                  mode="onBlur"
                />

                <Typography variant="body2">
                  Ao ativar a antecipação, o crédito será realizado na conta na
                  data em que o pagamento foi aprovado, acrescido dos dias
                  definidos para a antecipação.
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={1}>
          <BackButton
            className={classes.roundedButton}
            onClick={() => history.push(routes.priceTables.main)}
          >
            Voltar
          </BackButton>
        </Box>
        <Box>
          <Button
            form="priceTableForm"
            color="primary"
            variant="contained"
            type="submit"
            className={classes.roundedButton}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  )
}

PriceTablesForm.propTypes = {
  isEdit: PropTypes.bool,
  priceTable: PropTypes.object,
}

PriceTablesForm.defaultProps = {
  isEdit: false,
}

export default PriceTablesForm
