import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton } from 'components'
import { Button } from '@material-ui/core'
import helpers from 'helpers'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const ChargebackTableRow = ({ chargeback }) => {
  const history = useHistory()

  return (
    <>
      <TableRow key={chargeback?.id}>
        <TableCell>{chargeback?.order?.sellerName}</TableCell>
        <TableCell>
          {helpers.chargebacks.statusLabel(chargeback?.status)}
        </TableCell>
        <TableCell>
          {helpers.formatters.floatToMoney(chargeback?.order?.amount, true)}
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(chargeback?.occurrenceDate)}
        </TableCell>
        <TableCell align="right">
          <MenuButton>
            <Button
              fullWidth
              size="small"
              onClick={() =>
                history.push(
                  reverse(routes.chargebacks.edit, {
                    chargebackId: chargeback?.id,
                  }),
                )
              }
            >
              Editar
            </Button>
            <Button
              fullWidth
              size="small"
              onClick={() =>
                history.push(
                  reverse(routes.chargebacks.show, {
                    chargebackId: chargeback?.id,
                  }),
                )
              }
            >
              Detalhes
            </Button>
          </MenuButton>
        </TableCell>
      </TableRow>
    </>
  )
}

ChargebackTableRow.propTypes = {
  chargeback: PropTypes.object,
  refresh: PropTypes.func,
}

ChargebackTableRow.defaultProps = {
  refresh: () => {},
}

export default ChargebackTableRow
