import { Container, ContentHeader, LoadingFeedback, Page } from 'components'

import useFetch from 'hooks/useFetch'
import { PurchaserPaymentMethodTable } from './components'
import * as service from 'service'

const PurchaserPaymentMethodsMain = () => {
  const { response, isLoading } = useFetch(
    service.intermediador.purchaserPaymentMethods.get,
    {
      ...{ status: true },
    },
  )

  return (
    <>
      <Page title="Meio de pagamento por adquirente">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Meio de pagamento por adquirente"
            menu="Configurações"
            subtitle="Listagem"
          />
          <PurchaserPaymentMethodTable
            purchaserPaymentMethods={
              response?.data?.purchaserPaymentMethods ?? []
            }
          />
        </Container>
      </Page>
    </>
  )
}

export default PurchaserPaymentMethodsMain
