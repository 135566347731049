import { Button } from '@material-ui/core'

import useStyles from './styles'

const BackButton = (props) => {
  const classes = useStyles()

  return (
    <Button
      type="button"
      variant="outlined"
      className={classes.backButton}
      {...props}
    >
      Voltar
    </Button>
  )
}

export default BackButton
