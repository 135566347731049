import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { Box, Fab, Tooltip } from '@material-ui/core'

import * as service from 'service'
import { routes } from 'Routes'
import { HelpCircle as HelpIcon } from 'react-feather'

const DefaultComponent = ({ children }) => <>{children}</>

const RouteWithLayout = ({
  auth,
  layout,
  provider,
  component: Component,
  admin = false,
  redirect = true,
  ...rest
}) => {
  const Layout = layout || DefaultComponent
  const Provider = provider || DefaultComponent
  const isAuthenticated = service.intermediador.auth.isAuthenticated()

  if (!auth && isAuthenticated && redirect) {
    service.history.push(routes.home)
    return null
  }

  if (auth && !isAuthenticated) {
    service.history.push(routes.root)
    return null
  }

  const renderRoute = (matchProps) => {
    return (
      <Layout>
        <Provider component={Provider}>
          <Component {...matchProps} />
          <Box
            diplay="flex"
            margin="20px"
            position="fixed"
            zIndex={'99999'}
            bottom="0"
            right="0"
          >
            <Tooltip title="Acessar documentação dessa página">
              <Fab
                size="small"
                color="secondary"
                onClick={() => window.open('www.google.com.br')}
              >
                <HelpIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Provider>
      </Layout>
    )
  }

  return <Route {...rest} render={(matchProps) => renderRoute(matchProps)} />
}

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string,
  permission: PropTypes.string,
  admin: PropTypes.bool,
}

export default RouteWithLayout
