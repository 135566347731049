import Main from './ChargebacksMain'
import New from './ChargebacksNew'
import Edit from './ChargebacksEdit'
import Show from './ChargebacksShow'

const Chargebacks = {
  Main,
  New,
  Edit,
  Show,
}

export default Chargebacks
