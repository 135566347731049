const convertStringToRg = (rg) => {
  if (rg === null || rg === undefined) return ''

  let l = rg.length
  if (l <= 2) return rg

  if (l > 12) return rg.slice(0, 12)

  rg = rg.split('-').join('').split('.').join('')

  l = rg.length

  if (l <= 5) return rg.replace(/^(\d{2})(\d{1,3})*/, '$1.$2')
  if (l <= 8) return rg.replace(/^(\d{2})(\d{3})(\d{1,3}).*/, '$1.$2.$3')

  return rg.replace(/^(\d{2})(\d{3})(\d{3})(\d{1}).*/, '$1.$2.$3-$4')
}

export default convertStringToRg
