import {
  Home as HomeIcon,
  User as UserIcon,
  Repeat as RepeatIcon,
  DollarSign as DollarSignIcon,
  ArrowDownCircle as ArrowDownCircleIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
  File as FileIcon,
} from 'react-feather'

const ITEMS = [
  { title: 'Início', icon: <HomeIcon size={22} />, href: '/home' },
  { title: 'Clientes', icon: <UserIcon size={22} />, href: '/accounts' },
  {
    title: 'Chargebacks',
    icon: <RepeatIcon size={22} />,
    href: '/chargebacks',
  },
  {
    title: 'Tabela de preços',
    icon: <DollarSignIcon size={22} />,
    href: '/price-tables',
  },
  {
    title: 'Transações',
    icon: <ShoppingCartIcon size={22} />,
    href: '/orders',
  },
  {
    title: 'Saques',
    icon: <ArrowDownCircleIcon size={22} />,
    href: '/withdraws',
  },
  {
    title: 'Relatórios',
    icon: <FileIcon size={22} />,
    href: '/reports',
  },
  {
    title: 'Configurações',
    icon: <SettingsIcon size={22} />,
    href: '/settings',
  },
]

const menu = {
  ITEMS,
}

export default menu
