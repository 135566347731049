import PropTypes from 'prop-types'
import { Container, makeStyles } from '@material-ui/core'
import { Page, ContentHeader, LabelView } from 'components'

import useFetch from 'hooks/useFetch'
import * as service from 'service'

import { PriceTablesForm, PaymentMethodPriceTablesTable } from './components'
import styles from './styles'

const useStyles = makeStyles(styles)

const PriceTablesEdit = ({ match }) => {
  const classes = useStyles()

  const { priceTableId } = match.params
  const { response, isLoading } = useFetch(
    service.intermediador.priceTables.show,
    {
      priceTableId,
    },
  )

  return (
    <Page title="Edição de tabela de preço">
      <Container maxWidth={false} className={classes.rootNew}>
        <ContentHeader
          title="Edição"
          menu="Tabela de preço"
          subtitle="Edição de tabela de preço"
        />
        {!isLoading && (
          <>
            <PriceTablesForm
              isEdit
              priceTable={response?.data?.priceTable}
              isLoading={isLoading}
            />
            <LabelView label="Formas de pagamento e taxas" />
            <PaymentMethodPriceTablesTable
              paymentMethodPriceTables={
                response?.data?.priceTable?.paymentMethodPriceTables
              }
              priceTableToken={priceTableId}
            />
          </>
        )}
      </Container>
    </Page>
  )
}

PriceTablesEdit.propTypes = {
  match: PropTypes.object,
}

export default PriceTablesEdit
