import MuiTableCell from './MuiTableCell'
import MuiButton from './MuiButton'
import MuiFormLabel from './MuiFormLabel'
import MuiDialogActions from './MuiDialogActions'
import MuiPopover from './MuiPopover'

const overrides = {
  MuiTableCell,
  MuiButton,
  MuiFormLabel,
  MuiDialogActions,
  MuiPopover,
}

export default overrides
