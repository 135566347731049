import { useEffect } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'theme'
import { useLocation, Router } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'

import 'configs/yup/locale'

import 'react-perfect-scrollbar/dist/css/styles.css'

import AuthProvider from 'providers/AuthProvider'

import Routes from './Routes'
import history from 'service/history'

import { getBaseURL } from 'service/env'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Helmet } from 'react-helmet'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import 'react-perfect-scrollbar/dist/css/styles.css'

const App = () => {
  const isTestEnv = ['development', 'sandbox', 'test'].includes(
    process.env.REACT_APP_API,
  )

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            src: getBaseURL('auth'),
            name: 'leavening_login_with',
            'data-name': 'env',
            env: isTestEnv ? 'sandbox' : process.env.REACT_APP_API,
          },
        ]}
      />
      <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
        <Router history={history}>
          <ScrollToTop />
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default withStyles(styles)(App)
