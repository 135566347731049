const convertStringToCnpj = (cnpj) => {
  if (cnpj === null || cnpj === undefined) return ''

  let l = cnpj.length
  if (l <= 2) return cnpj

  if (l > 18) return cnpj.slice(0, 18)

  cnpj = cnpj.split('-').join('').split('.').join('').split('/').join('')

  l = cnpj.length

  if (l <= 5) return cnpj.replace(/^(\d{2})(\d{1,3})*/, '$1.$2')
  if (l <= 8) return cnpj.replace(/^(\d{2})(\d{1,3})(\d{1,3}).*/, '$1.$2.$3')
  if (l <= 12)
    return cnpj.replace(/^(\d{2})(\d{1,3})(\d{1,3})(\d{1,4}).*/, '$1.$2.$3/$4')

  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2}).*/,
    '$1.$2.$3/$4-$5',
  )
}

export default convertStringToCnpj
