import api from 'service/api'
import humps from 'humps'

import history from 'service/history'
import { AUTH_TOKEN, LOGIN_WITH_TOKEN, ACCOUNT_TOKEN } from 'service/constants'

const intermediadorAPI = api.create('intermediador', false)

const login = async (data) => {
  const response = await intermediadorAPI.post('/authentication_admin', data)
  const token = response.data.authToken

  setToken(token)
  setTokenLoginWith(data?.token)
  setAccountToken(getAccountFromToken())

  return response
}

const forgotPassword = async (data) => {
  const response = await intermediadorAPI.post('/users/password', {
    api_user: data,
  })

  return response
}

const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const setTokenLoginWith = (token) => {
  localStorage.setItem(LOGIN_WITH_TOKEN, token)
}

const setAccountToken = (token) => {
  localStorage.setItem(ACCOUNT_TOKEN, token)
}

const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

const getTokenLoginWIth = () => {
  return localStorage.getItem(LOGIN_WITH_TOKEN)
}

const getAccountToken = () => {
  return localStorage.getItem(ACCOUNT_TOKEN)
}

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(LOGIN_WITH_TOKEN)
  localStorage.removeItem(ACCOUNT_TOKEN)
  history.push('/')
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

const getPayload = () => {
  const token = getToken()
  return humps.camelizeKeys(JSON.parse(atob(token.split('.')[1])))
}

const getAccountFromToken = () => {
  return getPayload()?.accountToken
}

const getUserIdFromToken = () => {
  return getPayload()?.userId
}

const auth = {
  login,
  forgotPassword,
  logout,
  getToken,
  setToken,
  getTokenLoginWIth,
  getAccountToken,
  isAuthenticated,
  getUserIdFromToken,
}

export default auth
