const styles = (theme) => ({
  roundedTextField: {
    [`& fieldset`]: {
      borderRadius: theme.spacing(4),
    },
    width: '35%',
  },
  roundedButton: {
    borderRadius: theme.spacing(4),
  },
  fullHeight: {
    height: '100%',
  },
})

export default styles
