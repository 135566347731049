import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Grid, makeStyles } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  LoadingFeedback,
  BackButton,
  Select as SelectComponent,
  LabelView,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import styles from './styles'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const PurchaserPaymentMethodForm = ({
  isEdit,
  purchaserPaymentMethod,
  purchasers,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema(isEdit),
    defaultValues: {
      puchaserToken: purchaserPaymentMethod?.purchaser?.token || '',
    },
  })

  const onSubmit = async (data) => {
    try {
      await service.intermediador.purchaserPaymentMethods.edit({
        purchaserPaymentMethod: {
          purchaserToken: data?.purchaserToken,
          paymentMethodToken: purchaserPaymentMethod?.paymentMethod?.id,
        },
      })
      snackbar.open({
        message: `Meio de pagamento por adquirente atualizado com sucesso`,
        variant: 'success',
      })
      setLoading(false)
      history.push(routes.settings.purchaserPaymentMethods)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          `Falha ao atualizar o meio de pagamento por adquirente!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Box py={1}>
        <form id="purchaserPaymentMethodForm" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <LabelView
                label="Meio de pagamento"
                value={purchaserPaymentMethod?.paymentMethod?.name}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                as={
                  <SelectComponent
                    label="Adquirente"
                    items={purchasers || []}
                    error={!!errors.purchaser}
                    className={classes.roundedTextField}
                    helperText={errors?.purchaser?.message}
                  />
                }
                control={control}
                name="purchaserToken"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={1}>
          <BackButton
            className={classes.roundedButton}
            onClick={() =>
              history.push(routes.settings.purchaserPaymentMethods)
            }
          >
            Voltar
          </BackButton>
        </Box>
        <Box>
          <Button
            form="purchaserPaymentMethodForm"
            color="primary"
            variant="contained"
            type="submit"
            className={classes.roundedButton}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  )
}

PurchaserPaymentMethodForm.propTypes = {
  isEdit: PropTypes.bool,
  chargeback: PropTypes.object,
}

PurchaserPaymentMethodForm.defaultProps = {
  isEdit: false,
}

export default PurchaserPaymentMethodForm
