import Main from './PriceTablesMain'
import New from './PriceTablesNew'
import Edit from './PriceTablesEdit'
import Show from './PriceTablesShow'

const PriceTables = {
  Main,
  New,
  Edit,
  Show,
}

export default PriceTables
