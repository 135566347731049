import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  makeStyles,
} from '@material-ui/core'

import { Filter } from 'react-feather'

import styles from './styles'

const useStyles = makeStyles(styles)

const TableHeader = ({ setDrawerOpen }) => {
  const [pageLoader] = useState(false)
  const classes = useStyles()

  return (
    <div>
      {pageLoader ? (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      ) : (
        <Box className={classes.box}>
          <Tooltip title="Filtros">
            <Button
              onClick={() => setDrawerOpen(true)}
              startIcon={<Filter width="18" />}
              variant="outlined"
            >
              Filtros
            </Button>
          </Tooltip>
        </Box>
      )}
    </div>
  )
}

TableHeader.propTypes = {
  setDrawerOpen: PropTypes.func,
}

TableHeader.defaultProps = {
  setDrawerOpen: () => {},
}

export default TableHeader
