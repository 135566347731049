import { useState, useRef } from 'react'

import { Box, Menu, IconButton, makeStyles } from '@material-ui/core'

import { MoreVertical } from 'react-feather'

import styles from './styles'

const useStyles = makeStyles(styles)

const MenuButton = ({ children, ...rest }) => {
  const actionRef = useRef(null)
  const [isMenuOpen, setMenuOpen] = useState(false)

  const classes = useStyles()

  return (
    <Box>
      {children && (
        <Menu
          anchorEl={actionRef.current}
          onClose={() => setMenuOpen(false)}
          open={isMenuOpen}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          {...rest}
        >
          <Box className={classes.itemsContainer}>{children}</Box>
        </Menu>
      )}
      <IconButton ref={actionRef} onClick={() => setMenuOpen(true)}>
        <MoreVertical />
      </IconButton>
    </Box>
  )
}

export default MenuButton
