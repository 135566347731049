import PropTypes from 'prop-types'

import { useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

import { Box, Grid, Divider, Typography } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  Page,
  LabelView,
  BackButton,
  LoadingBox,
} from 'components'
import { useHistory } from 'react-router-dom'
import helpers from 'helpers'
import { PaymentMethodPriceTablesTable } from './components'

import * as service from 'service'
import constants from 'constants/index'
import { routes } from 'Routes'

const PriceTablesShow = ({ match }) => {
  const { priceTableId } = match.params
  const [priceTable, setPriceTable] = useState()
  const history = useHistory()

  const { response, isLoading } = useFetch(
    service.intermediador.priceTables.show,
    {
      priceTableId,
    },
    [],
  )

  useEffect(() => {
    setPriceTable(response?.data?.priceTable)
  }, [response])

  return (
    <Page title="Detalhes da tabela de preço">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Superfin"
          title="Tabela de preço"
          subtitle="Detalhes da tabela de preço"
        />
        <Box mt={3}>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              <Box p={2}>
                <Typography variant="h5">Detalhes</Typography>
              </Box>
              <Divider />
              <Box>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView label="Nome" value={priceTable?.name} />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView label="Versão" value={priceTable?.version} />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Status"
                      value={helpers.priceTables.statusLabel(
                        priceTable?.status,
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView
                      label="Descrição"
                      value={priceTable?.description}
                    />
                  </Grid>
                </Grid>
                {priceTable?.antecipation && (
                  <Grid container spacing={3}>
                    <Grid item sm={6}>
                      <LabelView
                        label="Antecipação"
                        value={
                          priceTable?.antecipation
                            ? priceTable?.antecipation
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <LabelView
                        label="Dias de antecipação"
                        value={
                          priceTable?.antecipationDays
                            ? priceTable?.antecipationDays
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                )}

                {priceTable?.status === constants.priceTables.ACTIVE_STATUS && (
                  <Grid container spacing={3}>
                    <Grid item sm={6}>
                      <LabelView
                        label="Aprovado em"
                        value={helpers.formatters.date(priceTable?.approvedIn)}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <LabelView
                        label="Aprovado por"
                        value={priceTable?.approvedBy?.name}
                      />
                    </Grid>
                  </Grid>
                )}
                <LabelView label="Formas de pagamento e taxas" />
                <PaymentMethodPriceTablesTable
                  paymentMethodPriceTables={
                    priceTable?.paymentMethodPriceTables
                  }
                />
              </Box>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box pr={1}>
                  <BackButton
                    onClick={() => history.push(routes.priceTables.main)}
                  >
                    Voltar
                  </BackButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Page>
  )
}

PriceTablesShow.propTypes = {
  match: PropTypes.object.isRequired,
}

export default PriceTablesShow
