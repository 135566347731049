import { Box, TablePagination, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  LoadingButton,
} from 'components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import { PriceTablesTable } from './components'
import constants from 'constants/index'
import * as service from 'service'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const PriceTablesMain = () => {
  const filter = useFilter()
  const history = useHistory()

  const theme = useTheme()
  const { perPage, page, handleChangePage } = usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.intermediador.priceTables.get,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <>
      <Page title="Tabela de preços">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Tabela de preços"
            subtitle="Tabela de preços"
          />
          <Box mt={4} display="flex" justifyContent="end">
            <Box mr={2}>
              <LoadingButton
                variant="contained"
                color="primary"
                text="Criar"
                onClick={() => history.push(routes.priceTables.new)}
              />
            </Box>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
          <PriceTablesTable
            priceTables={response?.data?.priceTables ?? []}
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount ?? 0}
              onChangePage={handleChangePage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Nome" name="name" />
          <input textfieldinput="true" label="Versão" name="version" />
          <input
            textfieldinput="true"
            label="Aprovado por"
            name="approved_by_name"
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={constants.priceTables.IN_PROGRESS_STATUS}>
              {constants.priceTables.IN_PROGRESS_LABEL}
            </option>
            <option value={constants.priceTables.WAITING_APPROVAL_STATUS}>
              {constants.priceTables.WAITING_APPROVAL_LABEL}
            </option>
            <option value={constants.priceTables.ACTIVE_STATUS}>
              {constants.priceTables.ACTIVE_LABEL}
            </option>
            <option value={constants.priceTables.INACTIVE_STATUS}>
              {constants.priceTables.INACTIVE_LABEL}
            </option>
          </select>
          <input
            datepickerinput="true"
            label="Aprovado em inicial"
            name="approvedInGt"
            disableFuture
            data-target="approvedInLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Aprovado em final"
            name="approvedInLt"
            disableFuture
            data-target="approvedInGt"
            data-max
          />
        </Filters>
      </Page>
    </>
  )
}

export default PriceTablesMain
