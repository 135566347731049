import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/withdraws', { params })
}

const processRemittance = async ({ ...data }) => {
  return await intermediadorAPI.post(`/withdraws/process_remittance`, data)
}

const sendRemittance = async ({ token }) => {
  return await intermediadorAPI.put(`/withdraws/${token}`)
}

const processWithdraw = async ({ token }) => {
  return await intermediadorAPI.put(`/withdraws/process/${token}`)
}

const withdraws = {
  get,
  processRemittance,
  sendRemittance,
  processWithdraw,
}

export default withdraws
