const styles = (theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    width: '100%',
    height: 'auto',
  },
  roundedTextField: {
    [`& fieldset`]: {
      borderRadius: theme.spacing(4),
    },
  },
  backButton: {
    backgroundColor: theme.palette.white,
    border: 0,
    color: theme.palette.black,
  },
  roundedButton: {
    borderRadius: theme.spacing(4),
  },
})

export default styles
