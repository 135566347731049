import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Tooltip,
} from '@material-ui/core'

const Select = ({ items, anotherOption = false, ...rest }) => {
  return (
    <TextField select fullWidth variant="outlined" {...rest}>
      {items.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          <Box width="100%">
            <Tooltip title={item.name || ''} placement="top">
              <Typography>{item.name}</Typography>
            </Tooltip>
          </Box>
        </MenuItem>
      ))}
      {anotherOption && (
        <MenuItem key="another" value="another">
          Outro
        </MenuItem>
      )}
    </TextField>
  )
}

export default Select
