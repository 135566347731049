import {
  Card,
  Box,
  Typography,
  Avatar,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const LoadingTextComponent = ({ loading, children }) => {
  if (loading) {
    return <CircularProgress color="inherit" size={24} disableShrink />
  }
  return children
}

const AccountBalance = ({
  title,
  subtitle,
  icon,
  loading,
  secondary = false,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Card
      className={`${classes.card} ${
        secondary && classes.backgroundCardSecondary
      }`}
      {...rest}
    >
      <Box p={2} display="flex" alignItems="center">
        <Box flexGrow={1} overflow="hidden">
          <Typography
            component="h3"
            gutterBottom
            variant="overline"
            color={secondary ? 'inherit' : 'textSecondary'}
            noWrap
          >
            {title}
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <LoadingTextComponent loading={loading}>
              <Typography
                variant="h3"
                color={secondary ? 'inherit' : 'textPrimary'}
              >
                {subtitle || '-'}
              </Typography>
            </LoadingTextComponent>
          </Box>
        </Box>
        <Avatar
          className={`${classes.avatar} ${
            secondary && classes.backgroundAvatarSecondary
          }`}
        >
          {icon}
        </Avatar>
      </Box>
    </Card>
  )
}

export default AccountBalance
