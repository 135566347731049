import {
  Grid,
  Box,
  Typography,
  TextField,
  makeStyles,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { Upload as UploadIcon } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'
import { Select as SelectComponent } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'

import styles from './styles'
import { routes } from 'Routes'
import clsx from 'clsx'
import schema from './schema'
import helpers from 'helpers'
import * as service from 'service'

const useStyles = makeStyles(styles)

const AccountsForm = ({ priceTables = [] }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      documentType: 'CNPJ',
    },
  })

  const onSubmit = async (data) => {
    try {
      await service.intermediador.accounts.create({
        account: {
          fantasyName: data.fantasyName,
          documentType: data.documentType,
          document: data.document,
          companyName: data.companyName,
          institutionalEmail: data.institutionalEmail.trim(),
          url: data.url,
          businessCategory: data.businessCategory,
          monthlyBillingRange: data.monthlyBillingRange,
          priceTableId: data.priceTableId,
          address: {
            city: data.city,
            state: data.state,
            cep: data.cep,
            street: data.street,
            neighborhood: data.neighborhood,
            complement: data.complement,
          },
          user: {
            name: data.name,
            document: data.cpf,
            rg: data.rg,
            celphone: data.celphone,
            documentType: 'CPF',
            birthDate: data.birthDate,
            email: data.email.trim(),
            companyRelation: data.companyRelation,
          },
          files: {
            socialContract: data.socialContract,
            proofOfAddress: data.proofOfAddress,
            cnpjCard: data.cnpjCard,
            responsableCpf: data.responsableCpf,
            responsableRg: data.responsableRg,
            bankAccountStatement: data.bankAccountStatement,
          },
        },
      })

      snackbar.open({
        message: `Cliente criado com sucesso`,
        variant: 'success',
      })

      history.push({ pathname: routes.accounts.main })
    } catch (error) {
      snackbar.open({
        message: 'Erro ao criar cliente',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <form id="accountForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography variant="h4">Informações do cliente</Typography>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <RadioGroup>
                  <Box display="flex" justifyContent="space-around">
                    <FormControlLabel
                      value="CNPJ"
                      control={<Radio color="primary" />}
                      label="Pessoa Jurídica"
                    />
                    <FormControlLabel
                      value="CPF"
                      control={<Radio color="primary" />}
                      label="Pessoa Fisica"
                    />
                  </Box>
                </RadioGroup>
              }
              control={control}
              name="documentType"
              mode="onChange"
            />
          </Grid>
          {watch('documentType') === 'CNPJ' ? (
            <Grid item lg={6} sm={12} xs={12}>
              <Controller
                as={
                  <TextField
                    label="CNPJ"
                    type="text"
                    color="primary"
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    className={classes.roundedTextField}
                    inputProps={{
                      maxLength: 18,
                    }}
                    fullWidth
                  />
                }
                control={control}
                onChange={([event]) => {
                  return helpers.formatters.cnpj(event.target.value)
                }}
                name="document"
                mode="onBlur"
              />
            </Grid>
          ) : (
            <Grid item lg={6} sm={12} xs={12}>
              <Controller
                as={
                  <TextField
                    label="CPF"
                    type="text"
                    color="primary"
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    className={classes.roundedTextField}
                    inputProps={{
                      maxLength: 14,
                    }}
                    fullWidth
                  />
                }
                control={control}
                onChange={([event]) => {
                  return helpers.formatters.cpf(event.target.value)
                }}
                name="document"
                mode="onBlur"
              />
            </Grid>
          )}
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Nome fantasia"
                  type="text"
                  color="primary"
                  error={!!errors.fantasyName}
                  helperText={errors?.fantasyName?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="fantasyName"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Razão social"
                  type="text"
                  color="primary"
                  error={!!errors.companyName}
                  helperText={errors?.companyName?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="companyName"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="E-mail institucional"
                  type="text"
                  color="primary"
                  error={!!errors.institutionalEmail}
                  helperText={errors?.institutionalEmail?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="institutionalEmail"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Url"
                  type="text"
                  color="primary"
                  error={!!errors.url}
                  helperText={errors?.url?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="url"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Categoria do négocio"
                  type="text"
                  color="primary"
                  error={!!errors.businessCategory}
                  helperText={errors?.businessCategory?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="businessCategory"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Faixa de faturamento mensal"
                  type="text"
                  color="primary"
                  error={!!errors.monthlyBillingRange}
                  helperText={errors?.monthlyBillingRange?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="monthlyBillingRange"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <SelectComponent
                  items={priceTables?.map((priceTable) => {
                    return {
                      id: priceTable.id,
                      name: `${priceTable.version} - ${priceTable.name}`,
                    }
                  })}
                  label="Tabela de preço"
                  error={!!errors.priceTable}
                  helperText={errors?.priceTable?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="priceTableId"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography variant="h4">Endereço</Typography>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Cidade"
                  type="text"
                  color="primary"
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="city"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Estado"
                  type="text"
                  color="primary"
                  error={!!errors.state}
                  helperText={errors?.state?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="state"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="CEP"
                  type="text"
                  color="primary"
                  error={!!errors.cep}
                  helperText={errors?.cep?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              onChange={([event]) => {
                return helpers.formatters.cep(event.target.value)
              }}
              name="cep"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Rua e número"
                  type="text"
                  color="primary"
                  error={!!errors.street}
                  helperText={errors?.street?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="street"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Bairro"
                  type="text"
                  color="primary"
                  error={!!errors.neighborhood}
                  helperText={errors?.neighborhood?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="neighborhood"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Complemento"
                  type="text"
                  color="primary"
                  error={!!errors.complement}
                  helperText={errors?.complement?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="complement"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography variant="h4">Usuário responsável</Typography>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Nome Completo"
                  type="text"
                  color="primary"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="name"
              mode="onBlur"
            />
          </Grid>

          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="CPF"
                  type="text"
                  color="primary"
                  error={!!errors.cpf}
                  helperText={errors?.cpf?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              onChange={([event]) => {
                return helpers.formatters.cpf(event.target.value)
              }}
              name="cpf"
              mode="onBlur"
            />
          </Grid>

          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="RG"
                  type="text"
                  color="primary"
                  error={!!errors.rg}
                  helperText={errors?.rg?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              onChange={([event]) => {
                return helpers.formatters.rg(event.target.value)
              }}
              name="rg"
              mode="onBlur"
            />
          </Grid>

          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <DatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data de nascimento"
                  helperText={errors?.birthDate?.message}
                  error={!!errors.birthDate}
                  inputVariant="outlined"
                  disableFuture
                  className={classes.roundedTextField}
                />
              }
              control={control}
              name="birthDate"
              mode="onBlur"
            />
          </Grid>

          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Telefone celular"
                  type="text"
                  color="primary"
                  error={!!errors.celphone}
                  helperText={errors?.celphone?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              onChange={([event]) => {
                return helpers.formatters.phone(event.target.value)
              }}
              name="celphone"
              mode="onBlur"
            />
          </Grid>

          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="E-mail"
                  type="text"
                  color="primary"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <SelectComponent
                  items={[
                    { id: 'Sócio majoritário', name: 'Sócio majoritário' },
                    { id: 'Sócio minoritário', name: 'Sócio minoritário' },
                    {
                      id: 'Procurador da empresa',
                      name: 'Procurador da empresa',
                    },
                  ]}
                  label="Relação com empresa"
                  error={!!errors.companyRelation}
                  helperText={errors?.companyRelation?.message}
                  className={classes.roundedTextField}
                  fullWidth
                />
              }
              control={control}
              name="companyRelation"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography variant="h4">Documentos</Typography>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<UploadIcon size={18} />}
                  component="label"
                  className={clsx(classes.roundedButton, classes.fullHeight)}
                  fullWidth
                >
                  <Box mr={2}>
                    <Typography>Contrato ou Estatuto Social</Typography>
                  </Box>
                  <input type="file" className={classes.input} />
                </Button>
              }
              control={control}
              name="socialContract"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<UploadIcon size={18} />}
                  component="label"
                  className={clsx(classes.roundedButton, classes.fullHeight)}
                  fullWidth
                >
                  <Box mr={2}>
                    <Typography>Comprovante de endereço</Typography>
                  </Box>
                  <input type="file" className={classes.input} />
                </Button>
              }
              control={control}
              name="proofOfAddress"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<UploadIcon size={18} />}
                  component="label"
                  className={clsx(classes.roundedButton, classes.fullHeight)}
                  fullWidth
                >
                  <Box mr={2}>
                    <Typography>Cartão CNPJ</Typography>
                  </Box>
                  <input type="file" className={classes.input} />
                </Button>
              }
              control={control}
              name="cnpjCard"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<UploadIcon size={18} />}
                  component="label"
                  className={clsx(classes.roundedButton, classes.fullHeight)}
                  fullWidth
                >
                  <Box mr={2}>
                    <Typography>CPF do Responsável</Typography>
                  </Box>
                  <input type="file" className={classes.input} />
                </Button>
              }
              control={control}
              name="responsableCpf"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<UploadIcon size={18} />}
                  component="label"
                  className={clsx(classes.roundedButton, classes.fullHeight)}
                  fullWidth
                >
                  <Box mr={2}>
                    <Typography>RG do Responsável</Typography>
                  </Box>
                  <input type="file" className={classes.input} />
                </Button>
              }
              control={control}
              name="responsableRg"
              mode="onBlur"
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Controller
              as={
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<UploadIcon size={18} />}
                  component="label"
                  className={clsx(classes.roundedButton, classes.fullHeight)}
                  fullWidth
                >
                  <Box mr={2}>
                    <Typography>Comprovante Bancário</Typography>
                  </Box>
                  <input type="file" className={classes.input} />
                </Button>
              }
              control={control}
              name="bankAccountStatement"
              mode="onBlur"
            />
          </Grid>
        </Grid>
      </form>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={1}>
          <Button
            type="button"
            variant="outlined"
            className={clsx(classes.backButton, classes.roundedButton)}
            onClick={() => history.push(routes.accounts.main)}
          >
            Voltar
          </Button>
        </Box>
        <Box>
          <Button
            form="accountForm"
            color="primary"
            variant="contained"
            type="submit"
            className={classes.roundedButton}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default AccountsForm
