import { Grid, makeStyles } from '@material-ui/core'
import { Container, ContentHeader, Page } from 'components'

import styles from './styles'

const useStyles = makeStyles(styles)

const Home = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Início">
      <Container maxWidth={false}>
        <ContentHeader menu="Início" title="Conta" subtitle="Resumo" />
        <Grid container spacing={3}></Grid>
      </Container>
    </Page>
  )
}

export default Home
