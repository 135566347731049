const styles = () => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 8px',
  },
  greenColor: {
    color: '#34a434',
  },
  priceBold: {
    fontWeight: 600,
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
