import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton } from 'components'
import { Button } from '@material-ui/core'
import helpers from 'helpers'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const PaymentMethodPriceTablesTableRow = ({
  paymentMethodPriceTable,
  priceTableToken,
}) => {
  const history = useHistory()

  return (
    <>
      <TableRow key={paymentMethodPriceTable?.id}>
        <TableCell>
          {helpers.paymentMethodPriceTables.conditionLabel(
            paymentMethodPriceTable?.condition,
          )}
        </TableCell>
        <TableCell>{paymentMethodPriceTable?.tax}</TableCell>
        <TableCell>{paymentMethodPriceTable?.paymentMethod?.name}</TableCell>
        <TableCell>{paymentMethodPriceTable?.split}</TableCell>
        <TableCell align="right">
          <MenuButton>
            <Button
              fullWidth
              size="small"
              onClick={() =>
                history.push({
                  pathname: reverse(routes.paymentMethodPriceTables.edit, {
                    paymentMethodPriceTableId: paymentMethodPriceTable?.id,
                  }),
                  state: {
                    priceTableToken: priceTableToken,
                    paymentMethodName:
                      paymentMethodPriceTable?.paymentMethod?.name,
                  },
                })
              }
            >
              Editar
            </Button>
          </MenuButton>
        </TableCell>
      </TableRow>
    </>
  )
}

PaymentMethodPriceTablesTableRow.propTypes = {
  paymentMethodPriceTable: PropTypes.object,
  refresh: PropTypes.func,
}

PaymentMethodPriceTablesTableRow.defaultProps = {
  refresh: () => {},
}

export default PaymentMethodPriceTablesTableRow
