import { Link as RouterLink } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { AppBar, Toolbar, Box, makeStyles } from '@material-ui/core'

import { Account } from './components'

import { Container } from 'components'

import styles from './styles'
import Logo from 'images/logo_superfin.png'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar
      elevation={0}
      position="relative"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="xl">
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <Box mb={1}>
            <RouterLink to={routes.home}>
              <img
                alt="Logo"
                src={Logo}
                className={classes.logo}
                width="100%"
              />
            </RouterLink>
          </Box>
          <Box ml={2} flexGrow={1} />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
