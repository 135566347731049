import PropTypes from 'prop-types'
import { Page, Container, ContentHeader } from 'components'

import useFetch from 'hooks/useFetch'
import * as service from 'service'
import { routes } from 'Routes'

import { ChargebackForm } from './components'

const ChargebackEdit = ({ match }) => {
  const {
    params: { chargebackId },
  } = match

  const navigations = [{ text: 'Listagem', route: routes.chargebacks.all }]

  const { response, isLoading, refresh } = useFetch(
    service.intermediador.chargebacks.get,
    {
      chargebackId,
    },
    [],
  )

  return (
    <Page title="Edição de chargeback">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Chargeback"
          subtitle="Edição de chargeback"
          menuNavigations={navigations}
        />
        {!isLoading && (
          <ChargebackForm
            isEdit
            chargeback={response?.data?.chargeback}
            isLoading={isLoading}
            refresh={refresh}
          />
        )}
      </Container>
    </Page>
  )
}

ChargebackEdit.propTypes = {
  match: PropTypes.object,
}

export default ChargebackEdit
