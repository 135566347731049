import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.orders.STATUSES.NOT_PAID_STATUS:
      return constants.orders.STATUSES_LABELS.NOT_PAID_LABEL
    case constants.orders.STATUSES.WAITING_PAYMENT_STATUS:
      return constants.orders.STATUSES_LABELS.WAITING_PAYMENT_LABEL
    case constants.orders.STATUSES.WAITING_CONFIRM_STATUS:
      return constants.orders.STATUSES_LABELS.WAITING_CONFIRM_LABEL
    case constants.orders.STATUSES.PAID_STATUS:
      return constants.orders.STATUSES_LABELS.PAID_LABEL
    case constants.orders.STATUSES.CANCELED_STATUS:
      return constants.orders.STATUSES_LABELS.CANCELED_LABEL
    case constants.orders.STATUSES.FAILURE_STATUS:
      return constants.orders.STATUSES_LABELS.FAILURE_LABEL
    case constants.orders.STATUSES.IN_CHARGEBACK_STATUS:
      return constants.orders.STATUSES_LABELS.IN_CHARGEBACK_LABEL
    default:
      return ''
  }
}

export default statusLabel
