import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import helpers from 'helpers'

const PaymentMethodPriceTablesTableRow = ({ paymentMethodPriceTable }) => {
  return (
    <>
      <TableRow key={paymentMethodPriceTable?.id}>
        <TableCell>
          {helpers.paymentMethodPriceTables.conditionLabel(
            paymentMethodPriceTable?.condition,
          )}
        </TableCell>
        <TableCell>{paymentMethodPriceTable?.tax}</TableCell>
        <TableCell>{paymentMethodPriceTable?.split}</TableCell>
      </TableRow>
    </>
  )
}

PaymentMethodPriceTablesTableRow.propTypes = {
  paymentMethodPriceTable: PropTypes.object,
}

export default PaymentMethodPriceTablesTableRow
