import { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router'
import { makeStyles, Box, Button, Typography } from '@material-ui/core'
import styles from './styles'
import { Menu as MenuIcon } from 'react-feather'
import constants from 'constants/index'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const NavBar = ({ openMobile, onMobileClose }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const [active, setActive] = useState(false)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname, active])

  const handleActive = (status) => {
    setActive(status)
  }

  const navigateTo = (route) => {
    history.push(route)
  }

  const selected = (href) =>
    matchPath(location.pathname, {
      path: href,
      exact: true,
    })

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column" ml={5}>
        <Box
          display="flex"
          flexDirection="column"
          className={clsx({
            [classes.activeMenu]: active,
            [classes.menu]: !active,
          })}
        >
          <Box width="100%">
            <Box display="flex" justifyContent="center" p={1}>
              <Button
                color="inherit"
                className={clsx({
                  [classes.buttonExtended]: active,
                  [classes.menuButton]: active,
                  [classes.button]: !active,
                })}
                onClick={() => {
                  handleActive(!active)
                }}
                fullWidth
              >
                <MenuIcon size={22} />
              </Button>
            </Box>
            {constants.menu.ITEMS &&
              constants.menu.ITEMS.map((item) => (
                <Box key={item.title} display="flex" alignItems="center" p={1}>
                  <Button
                    color="inherit"
                    className={clsx(classes.button, {
                      [classes.buttonExtended]: active,
                      [classes.active]: selected(item.href),
                    })}
                    classes={{
                      label: clsx({
                        [classes.buttonLabel]: active,
                      }),
                    }}
                    fullWidth={active}
                    onClick={() => navigateTo(item.href)}
                  >
                    {item.icon}
                    {active && (
                      <Typography variant="body1" className={classes.title}>
                        {item.title}
                      </Typography>
                    )}
                  </Button>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default NavBar
