import accounts from './accounts'
import auth from './auth'
import users from './users'
import chargebacks from './chargebacks'
import priceTables from './priceTables'
import paymentMethodPriceTables from './paymentMethodPriceTables'
import purchaserPaymentMethods from './purchaserPaymentMethods'
import purchasers from './purchasers'
import statements from './statements'
import orders from './orders'
import payments from './payments'
import withdraws from './withdraws'
import reports from './reports'

const intermediador = {
  accounts,
  auth,
  users,
  priceTables,
  chargebacks,
  paymentMethodPriceTables,
  purchasers,
  purchaserPaymentMethods,
  statements,
  orders,
  payments,
  withdraws,
  reports,
}

export default intermediador
