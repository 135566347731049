import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.chargebacks.IN_ANALYSIS_STATUS:
      return constants.chargebacks.IN_ANALYSIS_LABEL
    case constants.chargebacks.PAID_STATUS:
      return constants.chargebacks.PAID_LABEL
    case constants.chargebacks.DEBITED_STATUS:
      return constants.chargebacks.DEBITED_LABEL
    default:
      return ''
  }
}

export default statusLabel
