import PropTypes from 'prop-types'

import { TableCell as TableCellMaterial } from '@material-ui/core'

export const DEFAULT_BODY_SKELETON_HEIGHT = 20

const TableCell = ({
  bodySkeletonHeight = DEFAULT_BODY_SKELETON_HEIGHT,
  children,
  ...rest
}) => {
  return <TableCellMaterial {...rest}>{children}</TableCellMaterial>
}

TableCell.propTypes = {
  bodySkeletonHeight: PropTypes.number,
}

export default TableCell
