const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pagination: {
    backgroundColor: '#FFF',
    borderRadius: '20px',
  },
  rootNew: {
    paddingRight: theme.spacing(30),
    paddingLeft: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
})

export default styles
