const floatToMoney = (currency, dollarSign = false) => {
  if (!currency || typeof currency !== 'number') {
    if (dollarSign) return 'R$ 0.00'
    else return '0.00'
  }

  const value = currency.toFixed(2).toString()
  const cents = value.split('.')[1]
  const bills = value.split('.')[0].split('').reverse()
  var finalBill = ''

  for (let index in bills) {
    let actualPosition = Number(index) + 1
    finalBill += bills[index]
    if (actualPosition % 3 === 0 && bills.length !== actualPosition) {
      finalBill += '.'
    }
  }

  finalBill = finalBill.split('').reverse().join('')
  finalBill += `,${cents}`
  if (dollarSign) return `R$ ${finalBill}`
  else return finalBill
}

export default floatToMoney
