import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const me = async () => {
  return await intermediadorAPI.get('/users/me')
}

const users = {
  me,
}

export default users
