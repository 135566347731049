import constants from 'constants/index'

const conditionLabel = (condition) => {
  switch (condition) {
    case constants.paymentMethodPriceTables.SUBTRACTION_CONDITION:
      return constants.paymentMethodPriceTables.SUBTRACTION_LABEL
    case constants.paymentMethodPriceTables.PERCENTAGE_CONDITION:
      return constants.paymentMethodPriceTables.PERCENTAGE_LABEL
    default:
      return ''
  }
}

export default conditionLabel
