import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton } from 'components'
import { Button } from '@material-ui/core'
import helpers from 'helpers'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'
import constants from 'constants/index'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

const PriceTablesTableRow = ({ priceTable }) => {
  const snackbar = useSnackbar()
  const history = useHistory()

  const inactivatePriceTable = async () => {
    try {
      await service.intermediador.priceTables.edit({
        priceTableId: priceTable?.id,
        priceTable: {
          status: constants.priceTables.INACTIVE_STATUS,
        },
      })
      snackbar.open({
        message: 'Tabela de preço inativada com sucesso',
        variant: 'success',
      })
      setTimeout(() => {
        history.go(0)
      }, 2000)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          'Falha ao inativar a tabela de preço!',
        variant: 'error',
      })
    }
  }

  const waitingApprovalPriceTable = async () => {
    try {
      await service.intermediador.priceTables.edit({
        priceTableId: priceTable?.id,
        priceTable: {
          status: constants.priceTables.WAITING_APPROVAL_STATUS,
        },
      })
      snackbar.open({
        message: 'Tabela de preço aguardando aprovação com sucesso',
        variant: 'success',
      })
      setTimeout(() => {
        history.go(0)
      }, 2000)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          'Falha ao alterar o status para aguardando aprovação da tabela de preço!',
        variant: 'error',
      })
    }
  }

  const refuseApprovalPriceTable = async () => {
    try {
      await service.intermediador.priceTables.edit({
        priceTableId: priceTable?.id,
        priceTable: {
          status: constants.priceTables.IN_PROGRESS_STATUS,
        },
      })
      snackbar.open({
        message: 'Recusada a aprovação da tabela de preço',
        variant: 'success',
      })
      setTimeout(() => {
        history.go(0)
      }, 2000)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          'Falha ao recusar a aprovação da tabela de preço!',
        variant: 'error',
      })
    }
  }

  const approvePriceTable = async () => {
    try {
      await service.intermediador.priceTables.edit({
        priceTableId: priceTable?.id,
        priceTable: {
          status: constants.priceTables.ACTIVE_STATUS,
        },
      })
      snackbar.open({
        message: 'Tabela de preço ativa/aprovada com sucesso',
        variant: 'success',
      })
      setTimeout(() => {
        history.go(0)
      }, 2000)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          'Falha ao ativar/aprovar a tabela de preço!',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableRow key={priceTable?.id}>
        <TableCell>{priceTable?.name}</TableCell>
        <TableCell>{priceTable?.version}</TableCell>
        <TableCell>{priceTable?.description}</TableCell>
        <TableCell>
          {helpers.priceTables.statusLabel(priceTable?.status)}
        </TableCell>
        <TableCell>{priceTable?.approvedBy?.name}</TableCell>
        <TableCell>{helpers.formatters.date(priceTable?.approvedIn)}</TableCell>
        <TableCell align="right">
          <MenuButton>
            <Button
              fullWidth
              size="small"
              onClick={() =>
                history.push(
                  reverse(routes.priceTables.edit, {
                    priceTableId: priceTable?.id,
                  }),
                )
              }
            >
              Editar
            </Button>
            <Button
              fullWidth
              size="small"
              onClick={() =>
                history.push(
                  reverse(routes.priceTables.show, {
                    priceTableId: priceTable?.id,
                  }),
                )
              }
            >
              Detalhes
            </Button>
            {priceTable?.status !== constants.priceTables.INACTIVE_STATUS && (
              <Button fullWidth size="small" onClick={inactivatePriceTable}>
                Inativar
              </Button>
            )}
            {priceTable?.status ===
              constants.priceTables.IN_PROGRESS_STATUS && (
              <Button
                fullWidth
                size="small"
                onClick={waitingApprovalPriceTable}
              >
                Solicitar aprovação
              </Button>
            )}
            {priceTable?.status ===
              constants.priceTables.WAITING_APPROVAL_STATUS && (
              <Button fullWidth size="small" onClick={refuseApprovalPriceTable}>
                Recusar aprovação
              </Button>
            )}
            {priceTable?.status !== constants.priceTables.ACTIVE_STATUS && (
              <Button fullWidth size="small" onClick={approvePriceTable}>
                Ativar/Aprovar
              </Button>
            )}
          </MenuButton>
        </TableCell>
      </TableRow>
    </>
  )
}

PriceTablesTableRow.defaultProps = {
  priceTable: PropTypes.object,
}

export default PriceTablesTableRow
