import { colors } from '@material-ui/core'

const styles = (theme) => ({
  card: {
    height: '100%',
  },
  backgroundCardSecondary: {
    backgroundColor: theme.palette.primary.dark,
    color: colors.common.white,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48,
  },
  backgroundAvatarSecondary: {
    backgroundColor: colors.common.white,
    color: theme.palette.secondary.main,
  },
})

export default styles
