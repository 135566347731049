import { Container, makeStyles } from '@material-ui/core'
import { Page, ContentHeader } from 'components'
import { ChargebackForm } from './components'

import styles from './styles'

const useStyles = makeStyles(styles)

const ChargebacksNew = () => {
  const classes = useStyles()

  return (
    <Page title="Novo chargeback">
      <Container maxWidth={false} className={classes.rootNew}>
        <ContentHeader
          title="Novo"
          menu="Chargeback"
          subtitle="Novo chargeback"
        />
        <ChargebackForm />
      </Container>
    </Page>
  )
}
export default ChargebacksNew
