import { Container, Box } from '@material-ui/core'

import { ContentHeader, Page } from 'components'
import { ReportsForm } from './components'

const ReportsFinancial = () => {
  return (
    <Page title="Relátorio financeiro">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Relátorio"
          title="Financeiro"
          subtitle="Relátorio financeiro"
        />
        <Box mt={6}>
          <ReportsForm />
        </Box>
      </Container>
    </Page>
  )
}

export default ReportsFinancial
