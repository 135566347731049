import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box, makeStyles, Button } from '@material-ui/core'
import { MenuButton } from 'components'

import PerfectScrollbar from 'react-perfect-scrollbar'

import styles from './styles'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const PurchaserPaymentMethodTable = ({ purchaserPaymentMethods }) => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table
          size="medium"
          noWrap
          className={classes.table}
          emptyMessage="Nenhum encontrado"
        >
          <TableHead>
            <TableRow>
              <TableCell width="40%" align="left">
                Meio de pagamento
              </TableCell>
              <TableCell width="40%" align="left">
                Adquirente
              </TableCell>
              <TableCell width="20%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaserPaymentMethods.map((purchaserPaymentMethod) => (
              <TableRow key={purchaserPaymentMethod?.id}>
                <TableCell>
                  {purchaserPaymentMethod?.paymentMethod?.name}
                </TableCell>
                <TableCell>{purchaserPaymentMethod?.purchaser?.name}</TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <Button
                      fullWidth
                      size="small"
                      onClick={() =>
                        history.push(
                          reverse(routes.settings.editPurchaserPaymentMethods, {
                            purchaserPaymentMethodId:
                              purchaserPaymentMethod?.id,
                          }),
                        )
                      }
                    >
                      Editar
                    </Button>
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

PurchaserPaymentMethodTable.propTypes = {
  purchaserPaymentMethods: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

PurchaserPaymentMethodTable.defaultProps = {
  refresh: () => {},
}

export default PurchaserPaymentMethodTable
