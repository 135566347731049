import PropTypes from 'prop-types'

import { useEffect, useState } from 'react'

import { Box, Grid, Divider, Typography } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  Page,
  LabelView,
  LoadingBox,
} from 'components'
import helpers from 'helpers'

import * as service from 'service'

const ChargebacksShow = ({ match }) => {
  const chargebackId = match.params.chargebackId

  const [chargeback, setChargeback] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadChargeback = async () => {
      const response = await service.intermediador.chargebacks.show(
        chargebackId,
      )
      setChargeback(response?.data?.chargeback)
      setLoading(false)
    }
    loadChargeback()
    //eslint-disable-next-line
  }, [])

  return (
    <Page title="Detalhes do chargeback">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Superfin"
          title="Chargeback"
          subtitle="Detalhes do chargeback"
        />
        <Box mt={3}>
          {loading ? (
            <LoadingBox />
          ) : (
            <>
              <Box p={2}>
                <Typography variant="h5">Detalhes</Typography>
              </Box>
              <Divider />
              <Box>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView label="Motivo" value={chargeback?.reason} />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView
                      label="Valor"
                      value={
                        'R$ ' +
                        helpers.formatters.floatToMoney(
                          chargeback?.order?.amount,
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Cliente"
                      value={chargeback?.order?.sellerName}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView
                      label="URL comprovante"
                      value={chargeback?.proof}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Status"
                      value={helpers.chargebacks.statusLabel(
                        chargeback?.status,
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView label="Código nsu" value={chargeback?.nsuCode} />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Data de ocorrência"
                      value={helpers.formatters.date(
                        chargeback?.occurrenceDate,
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView
                      label="Prazo de contestação"
                      value={helpers.formatters.date(
                        chargeback?.contestationDeadline,
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Comentários do analista"
                      value={chargeback?.comment}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Page>
  )
}

ChargebacksShow.propTypes = {
  match: PropTypes.object.isRequired,
}

export default ChargebacksShow
