import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ chargebackId = '', ...params }) => {
  return await intermediadorAPI.get(`/chargebacks/${chargebackId}`, { params })
}

const create = async ({ ...params }) => {
  return await intermediadorAPI.post('/chargebacks', params)
}

const edit = async ({ chargebackId = '', ...params }) => {
  return await intermediadorAPI.put(`/chargebacks/${chargebackId}`, params)
}

const show = async (token) => {
  return await intermediadorAPI.get(`/chargebacks/${token}`)
}

const chargebacks = {
  get,
  create,
  edit,
  show,
}

export default chargebacks
