import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const hours = (date, utc = true) => {
  if (!date) {
    return null
  }
  date = moment(date)
  if (utc) {
    date.utc()
  }
  return date.format('HH:mm')
}

export default hours
