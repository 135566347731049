import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/accounts', { params })
}

const create = async ({ ...params }) => {
  return await intermediadorAPI.post('/accounts', params)
}

const accounts = {
  get,
  create,
}

export default accounts
