import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'

import * as service from 'service'
import { PurchaserPaymentMethodForm } from './components'

const PurchaserPaymentMethodsEdit = ({ match }) => {
  const {
    params: { purchaserPaymentMethodId },
  } = match
  const [purchaserPaymentMethod, setPurchaserPaymentMethod] = useState()
  const [purchasers, setPurchasers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      const response = await service.intermediador.purchaserPaymentMethods.get({
        purchaserPaymentMethodId,
      })
      setPurchaserPaymentMethod(response?.data?.purchaserPaymentMethod)

      const responsePurchasers = await service.intermediador.purchasers.get('')
      setPurchasers(responsePurchasers?.data?.purchasers)
      setLoading(false)
    }
    loadData()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Page title="Meio de pagamento por adquirente">
        <LoadingFeedback open={loading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Meio de pagamento por adquirente"
            menu="Configurações"
            subtitle="Editar"
          />
          <Box mt={3}>
            {!loading && (
              <PurchaserPaymentMethodForm
                isEdit
                purchaserPaymentMethod={purchaserPaymentMethod}
                purchasers={purchasers}
                loading={loading}
              />
            )}
          </Box>
        </Container>
      </Page>
    </>
  )
}

export default PurchaserPaymentMethodsEdit
