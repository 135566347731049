import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ ...params }) => {
  return await intermediadorAPI.get(`/price_tables`, { params })
}

const create = async ({ ...params }) => {
  return await intermediadorAPI.post('/price_tables', params)
}

const edit = async ({ priceTableId = '', ...params }) => {
  return await intermediadorAPI.put(`/price_tables/${priceTableId}`, params)
}

const show = async ({ priceTableId = '' }) => {
  return await intermediadorAPI.get(`/price_tables/${priceTableId}`)
}

const priceTables = {
  get,
  create,
  edit,
  show,
}

export default priceTables
