import { useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import {
  Box,
  makeStyles,
  Button,
  Checkbox,
  FormControl,
  Typography,
} from '@material-ui/core'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import PerfectScrollbar from 'react-perfect-scrollbar'
import useSnackbar from 'hooks/useSnackbar'

import styles from './styles'
import PropTypes from 'prop-types'
import helpers from 'helpers'
import { MenuButton } from 'components'
import { DialogProcessWithdraw } from '../'
import constants from 'constants/index'

import * as service from 'service'

const useStyles = makeStyles(styles)

const WithdrawsTable = ({ withdraws, refresh }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [openProcessDialog, setOpenProcessDialog] = useState(false)
  const [withdraw, setWithdraw] = useState()

  const [selectWithdraws, setSelectWithdraws] = useState([])

  const handleChange = (event) => {
    if (selectWithdraws.includes(event.target.value)) {
      setSelectWithdraws(
        selectWithdraws.filter((item) => item !== event.target.value),
      )
    } else {
      setSelectWithdraws([...selectWithdraws, event.target.value])
    }
  }

  const processRemittance = async () => {
    try {
      await service.intermediador.withdraws.processRemittance({
        tokens: selectWithdraws,
      })
      snackbar.open({
        message: `Processado com sucesso!`,
        variant: 'success',
      })
      setSelectWithdraws([])
      refresh()
    } catch (error) {
      snackbar.open({
        message: error?.response?.data?.error?.message,
        variant: 'error',
      })
    }
  }

  return (
    <Box width="100%">
      {selectWithdraws?.length > 0 ? (
        <Box
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          my={2}
          style={{ backgroundColor: '#b3f2c1', borderRadius: '40px' }}
        >
          <Typography variant="body1" color="primary">
            Selecionado(s): {selectWithdraws?.length}
          </Typography>
          <Button
            variant="contained"
            onClick={processRemittance}
            color="primary"
            size="small"
            style={{ borderRadius: '20px' }}
            disableElevation
          >
            Processar Saque
          </Button>
        </Box>
      ) : (
        ''
      )}
      <PerfectScrollbar>
        <FormControl style={{ width: '100%' }}>
          <Table
            size="medium"
            noWrap
            className={classes.table}
            emptyMessage="Nenhuma solicitação de saque encontrada"
          >
            <TableHead>
              <TableRow>
                <TableCell width="5%" align="left"></TableCell>
                <TableCell width="30%" align="left">
                  Nome da conta/Token
                </TableCell>
                <TableCell width="20%" align="left">
                  Valor
                </TableCell>
                <TableCell width="15%" align="left">
                  Status
                </TableCell>
                <TableCell width="20%" align="left">
                  Data de criação
                </TableCell>
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdraws.map((withdraw) => (
                <TableRow key={withdraw?.id} className={classes.test}>
                  <TableCell>
                    {withdraw?.status === 'pending' ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            defaultChecked={false}
                            value={withdraw?.id}
                          />
                        }
                      />
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {withdraw?.accountName}
                    <br />
                    {withdraw.id}
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.floatToMoney(withdraw?.amount, true)}
                  </TableCell>
                  <TableCell>
                    {helpers.withdraws.translateStatus(withdraw?.status)}
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.date(withdraw?.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    <MenuButton>
                      {withdraw.status.toUpperCase() ===
                        constants.withdraws.PENDING_LABEL_EN && (
                        <>
                          <Button
                            fullWidth
                            size="small"
                            onClick={() => {
                              setWithdraw(withdraw)
                              setOpenProcessDialog(true)
                            }}
                          >
                            ATUALIZAR STATUS PARA PROCESSADO
                          </Button>
                        </>
                      )}
                    </MenuButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </FormControl>
      </PerfectScrollbar>
      <DialogProcessWithdraw
        open={openProcessDialog}
        setOpen={setOpenProcessDialog}
        withdrawToken={withdraw?.id}
      />
    </Box>
  )
}

WithdrawsTable.propTypes = {
  withdraws: PropTypes.arrayOf(PropTypes.object),
}

export default WithdrawsTable
