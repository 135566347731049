import constants from 'constants/index'

const paymentMethodLabel = (paymentMethodName) => {
  switch (paymentMethodName) {
    case constants.paymentMethodPriceTables.MASTERCARD_LABEL:
      return constants.paymentMethodPriceTables.MASTERCARD_ID
    case constants.paymentMethodPriceTables.VISA_LABEL:
      return constants.paymentMethodPriceTables.VISA_ID
    case constants.paymentMethodPriceTables.AMEX_LABEL:
      return constants.paymentMethodPriceTables.AMEX_ID
    case constants.paymentMethodPriceTables.ELO_LABEL:
      return constants.paymentMethodPriceTables.ELO_ID
    case constants.paymentMethodPriceTables.PIX_LABEL:
      return constants.paymentMethodPriceTables.PIX_ID
    case constants.paymentMethodPriceTables.BILLET_LABEL:
      return constants.paymentMethodPriceTables.BILLET_ID
    default:
      return constants.paymentMethodPriceTables.HIPERCARD_ID
  }
}

export default paymentMethodLabel
