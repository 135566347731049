import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box, makeStyles } from '@material-ui/core'

import PriceTablesTableRow from '../PriceTablesTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import styles from './styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const PriceTablesTable = ({ priceTables, refresh }) => {
  const classes = useStyles()

  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table
          size="medium"
          noWrap
          className={classes.table}
          emptyMessage="Nenhuma tabela de preço encontrado"
        >
          <TableHead>
            <TableRow>
              <TableCell width="15%" align="left">
                Nome
              </TableCell>
              <TableCell width="15%" align="left">
                Versão
              </TableCell>
              <TableCell width="20%" align="left">
                Descrição
              </TableCell>
              <TableCell width="15%" align="center">
                Status
              </TableCell>
              <TableCell width="15%" align="center">
                Aprovado por
              </TableCell>
              <TableCell width="15%" align="center">
                Aprovado em
              </TableCell>
              <TableCell width="10%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priceTables.map((priceTable) => (
              <PriceTablesTableRow
                priceTable={priceTable}
                refresh={refresh}
                key={priceTable.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

PriceTablesTable.propTypes = {
  priceTables: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

PriceTablesTable.defaultProps = {
  refresh: () => {},
}

export default PriceTablesTable
