import * as yup from 'yup'

export const schema = yup.object().shape({
  documentType: yup.string().required(),
  document: yup.string().required(),
  fantasyName: yup.string().required(),
  companyName: yup.string().required(),
  institutionalEmail: yup.string().trim().email().required(),
  url: yup.string().required(),
  businessCategory: yup.string().required(),
  monthlyBillingRange: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  cep: yup.string().required(),
  street: yup.string().required(),
  neighborhood: yup.string().required(),
  complement: yup.string().required(),
  name: yup.string().required(),
  cpf: yup.string().required(),
  rg: yup.string().required(),
  birthDate: yup.string().required(),
  celphone: yup.string().required(),
  email: yup.string().trim().email().required(),
  priceTableId: yup.string().required(),
})

export default schema
