import { Box, TablePagination, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  LoadingButton,
} from 'components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import { ChargebackTables } from './components'
import constants from 'constants/index'
import * as service from 'service'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const ChargebacksMain = () => {
  const filter = useFilter()
  const history = useHistory()

  const theme = useTheme()
  const { perPage, page, handleChangePage } = usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.intermediador.chargebacks.get,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <>
      <Page title="Chargebacks">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Chargebacks"
            subtitle="Chargebacks"
          />
          <Box mt={4} display="flex" justifyContent="end">
            <Box mr={2}>
              <LoadingButton
                variant="contained"
                color="primary"
                text="Criar"
                onClick={() => history.push(routes.chargebacks.new)}
              />
            </Box>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
          <ChargebackTables
            chargebacks={response?.data?.chargebacks ?? []}
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount ?? 0}
              onChangePage={handleChangePage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Cliente" name="sellerName" />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={constants.chargebacks.IN_ANALYSIS_STATUS}>
              {constants.chargebacks.IN_ANALYSIS_LABEL}
            </option>
            <option value={constants.chargebacks.PAID_STATUS}>
              {constants.chargebacks.PAID_LABEL}
            </option>
            <option value={constants.chargebacks.DEBITED_STATUS}>
              {constants.chargebacks.DEBITED_LABEL}
            </option>
          </select>
          <input
            datepickerinput="true"
            label="Data de ocorrência inicial"
            name="occurrenceDateGt"
            disableFuture
            data-target="occurrenceDateLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de ocorrência final"
            name="occurrenceDateLt"
            disableFuture
            data-target="occurrenceDateGt"
            data-max
          />
        </Filters>
      </Page>
    </>
  )
}

export default ChargebacksMain
