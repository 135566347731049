import { BookOpen as BookOpenIcon } from 'react-feather'

const ITEMS = [
  {
    title: 'Meio de pagamento por adquirente',
    icon: <BookOpenIcon size={24} color="#325c81" />,
    subtitle: 'Visualize o meio de pagamnto e o adquirente selecionado.',
    href: '/settings/purchaser-payment-methods',
  },
]

const settingOptions = {
  ITEMS,
}

export default settingOptions
