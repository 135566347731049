import * as yup from 'yup'

const schemaDepartment = (isEdit) => {
  return yup.object().shape({
    ...(!isEdit && {
      orderId: yup.string().required(),
    }),
    ...(isEdit && {
      status: yup.string().required(),
    }),
    reason: yup.string().required(),
    proof: yup.string().required(),
    comment: yup.string().required(),
    nsuCode: yup.string().required(),
    occurrenceDate: yup.date().required(),
    contestationDeadline: yup.date().required(),
  })
}

export default schemaDepartment
