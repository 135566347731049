import { Box, TablePagination, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
} from 'components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import { OrdersTable } from './components'
import constants from 'constants/index'
import * as service from 'service'

const OrdersMain = () => {
  const filter = useFilter()

  const theme = useTheme()
  const { perPage, page, handleChangePage } = usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.intermediador.orders.get,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <>
      <Page title="Transações">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Transações"
            subtitle="Transações"
          />
          <Box mt={4} display="flex" justifyContent="end">
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
          <OrdersTable
            orders={response?.data?.orders ?? []}
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount ?? 0}
              onChangePage={handleChangePage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Container>
        <Filters filter={filter}>
          <input
            textfieldinput="true"
            label="Nome do vendedor"
            name="sellerName"
          />
          <input
            textfieldinput="true"
            label="Nome do comprador"
            name="buyerName"
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={constants.orders.STATUSES.NOT_PAID_STATUS}>
              {constants.orders.STATUSES_LABELS.NOT_PAID_LABEL}
            </option>
            <option value={constants.orders.STATUSES.WAITING_PAYMENT_STATUS}>
              {constants.orders.STATUSES_LABELS.WAITING_PAYMENT_LABEL}
            </option>
            <option value={constants.orders.STATUSES.WAITING_CONFIRM_STATUS}>
              {constants.orders.STATUSES_LABELS.WAITING_CONFIRM_LABEL}
            </option>
            <option value={constants.orders.STATUSES.PAID_STATUS}>
              {constants.orders.STATUSES_LABELS.PAID_LABEL}
            </option>
            <option value={constants.orders.STATUSES.CANCELED_STATUS}>
              {constants.orders.STATUSES_LABELS.CANCELED_LABEL}
            </option>
            <option value={constants.orders.STATUSES.FAILURE_STATUS}>
              {constants.orders.STATUSES_LABELS.FAILURE_LABEL}
            </option>
          </select>
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            disableFuture
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            disableFuture
            data-target="createdAtGt"
            data-max
          />
        </Filters>
      </Page>
    </>
  )
}

export default OrdersMain
