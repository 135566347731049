import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const edit = async ({ paymentMethodPriceTableId = '', ...params }) => {
  return await intermediadorAPI.put(
    `/payment_method_price_tables/${paymentMethodPriceTableId}`,
    params,
  )
}

const show = async ({ paymentMethodPriceTableId = '' }) => {
  return await intermediadorAPI.get(
    `/payment_method_price_tables/${paymentMethodPriceTableId}`,
  )
}

const index = async ({ ...params }) => {
  return await intermediadorAPI.get(`/payment_method_price_tables`, { params })
}

const paymentMethodPriceTables = {
  edit,
  show,
  index,
}

export default paymentMethodPriceTables
