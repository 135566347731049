const styles = () => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    minWidth: '30vw',
  },
})

export default styles
