const IN_ANALYSIS_STATUS = 'IN_ANALYSIS'
const PAID_STATUS = 'PAID'
const DEBITED_STATUS = 'DEBITED'

const IN_ANALYSIS_LABEL = 'EM ANÁLISE'
const PAID_LABEL = 'PAGO'
const DEBITED_LABEL = 'DEBITADO'

const SELECT_STATUS = [
  { id: IN_ANALYSIS_STATUS, name: IN_ANALYSIS_LABEL },
  { id: PAID_STATUS, name: PAID_LABEL },
  { id: DEBITED_STATUS, name: DEBITED_LABEL },
]

const chargebacks = {
  IN_ANALYSIS_STATUS,
  PAID_STATUS,
  DEBITED_STATUS,
  IN_ANALYSIS_LABEL,
  PAID_LABEL,
  DEBITED_LABEL,
  SELECT_STATUS,
}

export default chargebacks
