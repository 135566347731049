import { Switch, Redirect, Route } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { include } from 'named-urls'

import { Main as MainLayout } from './layouts'

import NotFound from 'views/NotFound'
import Forbidden from 'views/Forbidden'
import Home from 'views/Home'
import LandingPage from 'views/LandingPage'
import SnackbarProvider from 'providers/SnackbarProvider'
import Accounts from 'views/Accounts'
import Chargebacks from 'views/Chargebacks'
import PriceTables from 'views/PriceTables'
import Orders from 'views/Orders'
import Statements from 'views/Statements'
import Withdraws from 'views/Withdraws'
import PurchaserPaymentMethods from 'views/PurchaserPaymentMethods'
import Settings from 'views/Settings'
import PaymentMethodPriceTables from 'views/PaymentMethodPriceTables'
import Reports from 'views/Reports'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path={routes.home}
        component={Home}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.root}
        component={LandingPage}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.accounts.main}
        component={Accounts.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.accounts.new}
        component={Accounts.New}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.chargebacks.main}
        component={Chargebacks.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.chargebacks.new}
        component={Chargebacks.New}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.chargebacks.edit}
        component={Chargebacks.Edit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.chargebacks.show}
        component={Chargebacks.Show}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.priceTables.main}
        component={PriceTables.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.priceTables.new}
        component={PriceTables.New}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.priceTables.edit}
        component={PriceTables.Edit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.priceTables.show}
        component={PriceTables.Show}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.paymentMethodPriceTables.edit}
        component={PaymentMethodPriceTables.Edit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.statements.main}
        component={Statements.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.main}
        component={Settings.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.purchaserPaymentMethods}
        component={PurchaserPaymentMethods.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.editPurchaserPaymentMethods}
        component={PurchaserPaymentMethods.Edit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.orders.main}
        component={Orders.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.orders.show}
        component={Orders.Show}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.withdraws.main}
        component={Withdraws.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.main}
        component={Reports.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.financial}
        component={Reports.Financial}
        layout={MainLayout}
        exact
        auth
      />
      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

export const routes = {
  root: '/',
  home: '/home',
  notFound: '/not-found',
  forbidden: '/forbidden',
  accounts: include('/accounts', {
    main: '',
    new: 'new',
  }),
  chargebacks: include('/chargebacks', {
    main: '',
    new: 'new',
    edit: 'edit/:chargebackId',
    show: 'show/:chargebackId',
  }),
  priceTables: include('/price-tables', {
    main: '',
    new: 'new',
    edit: 'edit/:priceTableId',
    show: 'show/:priceTableId',
  }),
  paymentMethodPriceTables: include('/payment-method-price-tables', {
    edit: 'edit/:paymentMethodPriceTableId',
  }),
  statements: include('/statements', {
    main: ':accountId',
  }),
  settings: include('/settings', {
    main: '',
    purchaserPaymentMethods: 'purchaser-payment-methods',
    editPurchaserPaymentMethods:
      'edit/purchaser-payment-methods/:purchaserPaymentMethodId',
  }),
  orders: include('/orders', {
    main: '',
    show: ':orderId',
  }),
  withdraws: include('/withdraws', {
    main: '',
  }),
  reports: include('/reports', {
    main: '',
    financial: 'financial',
  }),
}

export default Routes
