const styles = (theme) => ({
  root: {
    height: '60px',
    backgroundColor: theme.palette.background.default,
  },
  logo: {
    width: '180px',
  },
  toolbar: {
    minHeight: 68,
  },
})

export default styles
