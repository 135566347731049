const mountTitle = (title) => {
  switch (title) {
    case 'general':
      return '- Geral'
    case 'bankAccount':
      return '- Conta bancaria'
    default:
      return ''
  }
}

export default mountTitle
