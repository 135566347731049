import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box, makeStyles } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

import styles from './styles'
import PropTypes from 'prop-types'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const OrderProductsTable = ({ products }) => {
  const classes = useStyles()

  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table
          size="medium"
          noWrap
          className={classes.table}
          emptyMessage="Nenhum produto encontrado"
        >
          <TableHead>
            <TableRow>
              <TableCell width="30%" align="left">
                Nome
              </TableCell>
              <TableCell width="30%" align="left">
                Descrição
              </TableCell>
              <TableCell width="20%" align="left">
                Preço
              </TableCell>
              <TableCell width="20%" align="left">
                Quantidade
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product?.name}</TableCell>
                <TableCell>{product?.description}</TableCell>
                <TableCell>
                  {helpers.formatters.floatToMoney(parseFloat(product?.price))}
                </TableCell>
                <TableCell>{product?.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

OrderProductsTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

OrderProductsTable.defaultProps = {
  refresh: () => {},
}

export default OrderProductsTable
