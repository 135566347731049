import palette from 'theme/palette'

const MuiButton = {
  label: {
    fontWeight: '700',
  },
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF',
  },
  containedPrimary: {
    fontWeight: '700',
    '&:hover': {
      backgroundColor: palette.secondary.main,
    },
  },
  outlined: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    '&:hover': {
      backgroundColor: palette.secondary.light,
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
}

export default MuiButton
