import palette from 'theme/palette'

const MuiFormLabel = {
  root: {
    '&$focused': {
      color: palette.secondary.dark,
    },
  },
}

export default MuiFormLabel
