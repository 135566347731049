import { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box, makeStyles, Button } from '@material-ui/core'
import { MenuButton } from 'components'
import DialogApprovePayment from '../DialogApprovePayment'

import PerfectScrollbar from 'react-perfect-scrollbar'

import styles from './styles'
import PropTypes from 'prop-types'
import helpers from 'helpers'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const OrderPaymentsTable = ({ payments, order }) => {
  const classes = useStyles()
  const [openApproveDialog, setOpenApproveDialog] = useState(false)
  const [payment, setPayment] = useState()

  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table
          size="medium"
          noWrap
          className={classes.table}
          emptyMessage="Nenhum pagamento encontrado"
        >
          <TableHead>
            <TableRow>
              <TableCell width="10%" align="left">
                Valor
              </TableCell>
              <TableCell width="20%" align="left">
                Meio de pagamento
              </TableCell>
              <TableCell width="30%" align="left">
                Status
              </TableCell>
              <TableCell width="20%" align="left">
                Data de aprovação
              </TableCell>
              <TableCell width="10%" align="left">
                Número de parcelas
              </TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments?.map((payment, index) => (
              <TableRow key={index}>
                <TableCell>
                  {helpers.formatters.floatToMoney(parseFloat(payment?.amount))}
                </TableCell>
                <TableCell>{payment?.paymentMethod?.description}</TableCell>
                <TableCell>
                  {helpers.payments.statusLabel(payment?.status)}
                </TableCell>
                <TableCell>
                  {helpers.formatters.date(payment?.approvalDate) || 'N/A'}
                </TableCell>
                <TableCell>{payment?.splitNumber || 'N/A'}</TableCell>
                <TableCell align="right">
                  {order?.status ===
                    constants.orders.STATUSES.NOT_PAID_STATUS ||
                  order?.status ===
                    constants.orders.STATUSES.WAITING_PAYMENT_STATUS ? (
                    <MenuButton>
                      <Button
                        fullWidth
                        size="small"
                        onClick={() => {
                          setPayment(payment)
                          setOpenApproveDialog(true)
                        }}
                      >
                        Aprovar
                      </Button>
                    </MenuButton>
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <DialogApprovePayment
        open={openApproveDialog}
        setOpen={setOpenApproveDialog}
        payment={payment}
      />
    </Box>
  )
}

OrderPaymentsTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
}

export default OrderPaymentsTable
