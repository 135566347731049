import { TableContainer, makeStyles, Button } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { MenuButton } from 'components'
import helpers from 'helpers'
import styles from './styles'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const AccountsTable = ({ isLoading, accounts = [] }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <PerfectScrollbar>
        <TableContainer>
          <Table
            isLoading={isLoading}
            emptyMessage="Nenhum cliente encontrado"
            size="medium"
            noWrap
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell width="10%">ID</TableCell>
                <TableCell width="15%">Nome</TableCell>
                <TableCell width="15%">CNPJ</TableCell>
                <TableCell width="15%">Nome fantasia</TableCell>
                <TableCell width="15%">Razão Social</TableCell>
                <TableCell width="15%">Email institucional</TableCell>
                <TableCell align="right" width="15%">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts?.map((account) => (
                <TableRow key={account.id}>
                  <TableCell>{account.id}</TableCell>
                  <TableCell>{account.name}</TableCell>
                  <TableCell>
                    {helpers.formatters.cnpj(account?.cnpj)}
                  </TableCell>
                  <TableCell>{account.fantasyName}</TableCell>
                  <TableCell>{account.companyName}</TableCell>
                  <TableCell>{account.institutionalEmail}</TableCell>
                  <TableCell align="right">
                    <MenuButton>
                      <Button
                        fullWidth
                        size="small"
                        onClick={() =>
                          history.push(
                            reverse(routes.statements.main, {
                              accountId: account.id,
                            }),
                          )
                        }
                      >
                        Ver Extrato
                      </Button>
                    </MenuButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </>
  )
}

export default AccountsTable
