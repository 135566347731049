const IN_PROGRESS_STATUS = 'IN_PROGRESS'
const WAITING_APPROVAL_STATUS = 'WAITING_APPROVAL'
const ACTIVE_STATUS = 'ACTIVE'
const INACTIVE_STATUS = 'INACTIVE'

const IN_PROGRESS_LABEL = 'EM PROGRESSO'
const WAITING_APPROVAL_LABEL = 'AGUARDANDO APROVAÇÃO'
const ACTIVE_LABEL = 'ATIVO'
const INACTIVE_LABEL = 'INATIVO'

const SELECT_STATUS = [
  { id: IN_PROGRESS_STATUS, name: IN_PROGRESS_LABEL },
  { id: WAITING_APPROVAL_STATUS, name: WAITING_APPROVAL_LABEL },
  { id: ACTIVE_STATUS, name: ACTIVE_LABEL },
  { id: INACTIVE_STATUS, name: INACTIVE_LABEL },
]

const priceTables = {
  IN_PROGRESS_STATUS,
  WAITING_APPROVAL_STATUS,
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  IN_PROGRESS_LABEL,
  WAITING_APPROVAL_LABEL,
  ACTIVE_LABEL,
  INACTIVE_LABEL,
  SELECT_STATUS,
}

export default priceTables
