import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box, makeStyles } from '@material-ui/core'

import ChargebackTableRow from '../ChargebackTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import styles from './styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const ChargebackTable = ({ chargebacks, refresh }) => {
  const classes = useStyles()

  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table
          size="medium"
          noWrap
          className={classes.table}
          emptyMessage="Nenhum chargeback encontrado"
        >
          <TableHead>
            <TableRow>
              <TableCell width="30%" align="left">
                Cliente
              </TableCell>
              <TableCell width="20%" align="left">
                Status
              </TableCell>
              <TableCell width="20%" align="left">
                Valor
              </TableCell>
              <TableCell width="20%" align="center">
                Data de ocorrência
              </TableCell>
              <TableCell width="10%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargebacks.map((chargeback) => (
              <ChargebackTableRow
                chargeback={chargeback}
                refresh={refresh}
                key={chargeback.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

ChargebackTable.propTypes = {
  chargebacks: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

ChargebackTable.defaultProps = {
  refresh: () => {},
}

export default ChargebackTable
