const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  titleBox: {
    borderRadius: '20px',
    width: '100%',
    background:
      'linear-gradient(90deg, rgba(8,109,161,255) 0%, rgba(21,150,164,1) 100%)',
    marginBottom: theme.spacing(4),
  },
  textWhite: {
    color: theme.palette.white,
  },
})

export default styles
