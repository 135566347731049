import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  TextField,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  LoadingFeedback,
  BackButton,
  Select as SelectComponent,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import styles from './styles'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const PaymentMethodPriceTablesForm = ({
  isEdit,
  paymentMethodPriceTables,
  isPixOrBillet,
  refreshTable,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const snackbar = useSnackbar()
  const paymentMethodPriceTableDefault = paymentMethodPriceTables?.find(
    (paymentMethodPriceTable) => paymentMethodPriceTable.split === 1,
  )

  const [paymentMethodPriceTable, setPaymentMethodPriceTable] = useState(
    paymentMethodPriceTableDefault,
  )

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema(isEdit),
    defaultValues: {
      tax: paymentMethodPriceTable?.tax || 0,
      condition: paymentMethodPriceTable?.condition || '',
      split: paymentMethodPriceTable?.split || 1,
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.intermediador.paymentMethodPriceTables.edit({
          paymentMethodPriceTableId: paymentMethodPriceTable?.id,
          paymentMethodPriceTable: {
            tax: data?.tax,
            condition: data?.condition,
          },
        })
      } else {
        await service.intermediador.paymentMethodPriceTables.create({
          paymentMethodPriceTable: {
            tax: data?.tax,
            condition: data?.condition,
          },
        })
      }
      snackbar.open({
        message: `Forma de pagamento da tabela de preço ${
          isEdit ? 'atualizada' : 'criada'
        }  com sucesso`,
        variant: 'success',
      })
      setLoading(false)
      refreshTable()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          `Falha ao ${
            isEdit ? 'atualizar' : 'criar'
          } a forma de pagamento da tabela de preço!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const handleChange = (target) => {
    const paymentMethodPriceTableUpdated = paymentMethodPriceTables?.find(
      (paymentMethodPriceTable) =>
        paymentMethodPriceTable.split === target.value,
    )
    setPaymentMethodPriceTable(paymentMethodPriceTableUpdated)
  }

  useEffect(() => {
    reset(paymentMethodPriceTable)
  }, [paymentMethodPriceTable])

  useEffect(() => {
    setPaymentMethodPriceTable(paymentMethodPriceTableDefault)
  }, [paymentMethodPriceTableDefault])

  return (
    <>
      <LoadingFeedback open={loading} />
      <Typography>
        Forma de pagamento: {paymentMethodPriceTable?.paymentMethod?.name}
      </Typography>
      <Box py={1} mt={2}>
        <form
          id="paymentMethodPriceTableForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container style={{ gap: 20 }}>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Taxa"
                    type="number"
                    color="primary"
                    variant="outlined"
                    error={!!errors.tax}
                    className={classes.roundedTextField}
                    helperText={errors?.tax?.message}
                    fullWidth
                  />
                }
                control={control}
                name="tax"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <SelectComponent
                    label="Condição"
                    items={constants.paymentMethodPriceTables.SELECT_CONDITION}
                    error={!!errors.condition}
                    className={classes.roundedTextField}
                    helperText={errors?.condition?.message}
                  />
                }
                control={control}
                name="condition"
                mode="onBlur"
              />
            </Grid>
            {!isPixOrBillet && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <SelectComponent
                      label="Parcela"
                      items={constants.paymentMethodPriceTables.SPLITS}
                      error={!!errors.split}
                      className={classes.roundedTextField}
                      helperText={errors?.split?.message}
                      defaultValue="1"
                    />
                  }
                  control={control}
                  name="split"
                  mode="onBlur"
                  onChange={([e]) => handleChange(e.target)}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={1}>
          <BackButton
            className={classes.roundedButton}
            onClick={() => history.goBack()}
          >
            Voltar
          </BackButton>
        </Box>
        <Box>
          <Button
            form="paymentMethodPriceTableForm"
            color="primary"
            variant="contained"
            type="submit"
            className={classes.roundedButton}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  )
}

PaymentMethodPriceTablesForm.propTypes = {
  isEdit: PropTypes.bool,
  paymentMethodPriceTable: PropTypes.object,
  isPixOrBillet: PropTypes.bool,
}

PaymentMethodPriceTablesForm.defaultProps = {
  isEdit: false,
}

export default PaymentMethodPriceTablesForm
