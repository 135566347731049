import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card as CardMaterial,
  Typography,
  Collapse,
} from '@material-ui/core'
import { ToggleButton } from 'components'
import useStyles from './styles'

const Title = ({ iconTitle, title }) => {
  if (iconTitle) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridGap={5}
      >
        {iconTitle} <Typography variant="h5">{title}</Typography>
      </Box>
    )
  }

  return <Typography variant="h5">{title}</Typography>
}

const ButtonHeader = ({ actionButton, dropdown, expanded, setExpanded }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {actionButton && <Box pr={1}>{actionButton}</Box>}
      {dropdown && (
        <Box pr={1}>
          <ToggleButton
            size={18}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        </Box>
      )}
    </Box>
  )
}

const Card = ({
  title,
  dropdown,
  children,
  actionButton,
  paddingCard,
  iconTitle,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  return (
    <Box pb={paddingCard}>
      <CardMaterial>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.borderBottom}
          pl={2}
          py={2}
        >
          <Title title={title} iconTitle={iconTitle} />
          <ButtonHeader
            actionButton={actionButton}
            dropdown={dropdown}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </Box>
        {children && !dropdown ? (
          <Box p={2}>{children}</Box>
        ) : (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {children}
          </Collapse>
        )}
      </CardMaterial>
    </Box>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  dropdown: PropTypes.bool,
  children: PropTypes.node,
  actionButton: PropTypes.node,
  paddingCard: PropTypes.number,
  iconTitle: PropTypes.node,
}

Card.defaultProps = {
  dropdown: false,
  actionButton: null,
  paddingCard: 2,
  iconTitle: null,
}

export default Card
