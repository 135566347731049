import { Container, makeStyles } from '@material-ui/core'
import { Page, ContentHeader } from 'components'
import { PriceTablesForm } from './components'

import styles from './styles'

const useStyles = makeStyles(styles)

const PriceTablesNew = () => {
  const classes = useStyles()

  return (
    <Page title="Nova tabela de preço">
      <Container maxWidth={false} className={classes.rootNew}>
        <ContentHeader
          title="Novo"
          menu="Tabela de preço"
          subtitle="Nova tabela de preço"
        />
        <PriceTablesForm />
      </Container>
    </Page>
  )
}
export default PriceTablesNew
