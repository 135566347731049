import PropTypes from 'prop-types'
import { Container, makeStyles } from '@material-ui/core'
import { Page, ContentHeader } from 'components'
import { useLocation } from 'react-router-dom'
import useFetch from 'hooks/useFetch'
import * as service from 'service'

import { PaymentMethodPriceTablesForm } from './components'
import styles from './styles'
import { PaymentMethodPriceTablesTable } from './components/PaymentMethodPriceTablesForm/components/'
import helpers from 'helpers'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(styles)

const PaymentMethodPriceTablesEdit = () => {
  const classes = useStyles()
  const location = useLocation()
  const [paymentMethodPriceTables, setPaymentMethodPriceTables] = useState([])
  const [loading, setLoading] = useState(false)
  const [refreshKey, setRefreshKey] = useState(1)
  const refreshTable = () => {
    setRefreshKey(refreshKey + 1)
  }

  const paymentMethodPriceTableParams =
    helpers.paymentMethodPriceTables.paymentMethodLabel(
      location.state.paymentMethodName,
    )
  const priceTableToken = location.state.priceTableToken

  const isPixOrBillet = ['Pix', 'Boleto'].includes(
    location.state.paymentMethodName,
  )

  useEffect(() => {
    const loadPaymentMethodPriceTables = async () => {
      setLoading(true)
      const response =
        await service.intermediador.paymentMethodPriceTables.index({
          paymentMethodId: paymentMethodPriceTableParams,
          priceTableToken: priceTableToken,
        })
      setPaymentMethodPriceTables(response?.data?.paymentMethodPriceTables)
    }
    loadPaymentMethodPriceTables()
    setLoading(false)
  }, [refreshKey])

  return (
    <Page title="Edição da forma de pagamento da tabela de preço">
      <Container maxWidth={false} className={classes.rootNew}>
        <ContentHeader
          title="Edição"
          menu="Tabela de preço"
          subtitle="Edição da forma de pagamento da tabela de preço"
        />
        {!loading && (
          <>
            <PaymentMethodPriceTablesForm
              isEdit
              paymentMethodPriceTables={paymentMethodPriceTables}
              priceTableToken={priceTableToken}
              isPixOrBillet={isPixOrBillet}
              refreshTable={refreshTable}
            />
            {!isPixOrBillet && (
              <PaymentMethodPriceTablesTable
                paymentMethodPriceTables={paymentMethodPriceTables}
              />
            )}
          </>
        )}
      </Container>
    </Page>
  )
}

PaymentMethodPriceTablesEdit.propTypes = {
  match: PropTypes.object,
}

export default PaymentMethodPriceTablesEdit
