import { useHistory } from 'react-router-dom'
import { Page } from 'components'
import { TopBar } from './components'

import { COMPANY_TOKEN_LOGIN_WITH_ID } from 'service/env'
import { routes } from 'Routes'

import * as service from 'service'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

const LandingPage = () => {
  const auth = useAuth()
  const snackbar = useSnackbar()
  const history = useHistory()

  const loginWithButton = async () => {
    const response = await window.getTokenAuth()

    await service.intermediador.auth.login({
      companyToken: COMPANY_TOKEN_LOGIN_WITH_ID,
      token: response?.token,
    })

    history.replace(routes.home)
    auth.loadDataWithAnimation()
  }

  const signUpButton = async () => {
    const response = await window.signUpOneID()
    snackbar.open({ message: response.message, variant: response.status })
  }

  return (
    <Page title="Superfin">
      <input
        type="hidden"
        id="leavening_company_token"
        value={COMPANY_TOKEN_LOGIN_WITH_ID}
      />
      <TopBar loginWithButton={loginWithButton} signUpButton={signUpButton} />
    </Page>
  )
}

export default LandingPage
