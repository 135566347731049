import * as yup from 'yup'

const schema = (isEdit) => {
  return yup.object().shape({
    ...(!isEdit && {
      name: yup.string().required(),
    }),
    ...(isEdit && {
      status: yup.string().required(),
    }),
    description: yup.string().required(),
    oldTableId: yup.string(),
    antecipation: yup.string(),
    antecipationDays: yup.string(),
  })
}

export default schema
