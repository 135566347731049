import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const financial = ({ ...params }) => {
  return intermediadorAPI.get(`/reports/financial.csv`, { params })
}

const reports = {
  financial,
}

export default reports
