import upperText from './upperText'
import capitalizeText from './capitalizeText'
import reduceRoutesToArray from './reduceRoutesToArray'
import isEmpty from './isEmpty'
import mountTitle from './mountTitle'

const functions = {
  upperText,
  capitalizeText,
  reduceRoutesToArray,
  isEmpty,
  mountTitle,
}

export default functions
