import { TableContainer, makeStyles, IconButton } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { ChevronRight as ChevronRightIcon } from 'react-feather'
import helpers from 'helpers'
import theme from 'theme'
import styles from './styles'

const useStyles = makeStyles(styles)

const StatementssTable = ({ isLoading, statements = [] }) => {
  const classes = useStyles()

  return (
    <>
      <PerfectScrollbar>
        <TableContainer>
          <Table
            isLoading={isLoading}
            emptyMessage="Extrato não encontrado"
            size="medium"
            noWrap
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell width="20%">Valor</TableCell>
                <TableCell width="20%">Tipo</TableCell>
                <TableCell width="20%">Saldo da conta</TableCell>
                <TableCell width="20%">Data de criação</TableCell>
                <TableCell align="center" width="20%">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements?.map((statement) => (
                <TableRow key={statement.id}>
                  <TableCell>
                    {helpers.formatters.floatToMoney(
                      statement?.amountWithFee,
                      true,
                    )}
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.statementType(
                      statement?.transactionType,
                    )}
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.floatToMoney(
                      statement?.accountAmount,
                      true,
                    )}
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.date(statement?.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <ChevronRightIcon
                        strokeWidth={3}
                        size={24}
                        color={theme.palette.text.primary}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </>
  )
}

export default StatementssTable
