import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ orderId = '', ...params }) => {
  return await intermediadorAPI.get(`/orders/${orderId}`, { params })
}

const orders = {
  get,
}

export default orders
