import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ ...params }) => {
  return await intermediadorAPI.get(`/account_statements`, { params })
}

const statements = {
  get,
}

export default statements
