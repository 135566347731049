import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import PaymentMethodPriceTablesTableRow from '../PaymentMethodPriceTablesTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const PaymentMethodPriceTablesTable = ({
  paymentMethodPriceTables,
  priceTableToken,
}) => {
  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table
          size="medium"
          noWrap
          emptyMessage="Nenhuma tabela de preço encontrado"
        >
          <TableHead>
            <TableRow>
              <TableCell width="30%" align="left">
                Condição
              </TableCell>
              <TableCell width="15%" align="left">
                Taxa
              </TableCell>
              <TableCell width="30%" align="left">
                Método de pagamento
              </TableCell>
              <TableCell width="15%" align="left">
                Parcela
              </TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentMethodPriceTables
              ?.sort((a, b) => a.paymentMethod?.id - b.paymentMethod?.id)
              .map((paymentMethodPriceTable) => (
                <PaymentMethodPriceTablesTableRow
                  paymentMethodPriceTable={paymentMethodPriceTable}
                  priceTableToken={priceTableToken}
                  key={paymentMethodPriceTable.id}
                />
              ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

PaymentMethodPriceTablesTable.propTypes = {
  paymentMethodPriceTables: PropTypes.arrayOf(PropTypes.object),
}

export default PaymentMethodPriceTablesTable
