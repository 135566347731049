import { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, Box, Hidden } from '@material-ui/core'

import { TopBar, ScreenLoading, NavBar, MenuBar } from './components'
import useAuth from 'hooks/useAuth'

import styles from './styles'

import SnackbarProvider from 'providers/SnackbarProvider'

const useStyles = makeStyles(styles)

const Main = ({ children }) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const auth = useAuth()

  return (
    <ScreenLoading loaded={auth.loaded}>
      <SnackbarProvider>
        <Box className={classes.root}>
          <Hidden mdDown>
            <Box display="flex" alignItems="center">
              <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
              />
            </Box>
          </Hidden>
          <Box className={classes.wrapper}>
            <Box className={classes.contentContainer}>
              <Box className={classes.content}>
                <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
                {children}
                <Hidden lgUp>
                  <Box pb={10}></Box>
                  <Box className={classes.menuBottom}>
                    <MenuBar />
                  </Box>
                </Hidden>
              </Box>
            </Box>
          </Box>
        </Box>
      </SnackbarProvider>
    </ScreenLoading>
  )
}

Main.propTypes = {
  children: PropTypes.any,
}

export default Main
