import { useState } from 'react'
import PropTypes from 'prop-types'

import { DatePicker } from '@material-ui/pickers'
import { Box, Button, TextField, Grid, makeStyles } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  LoadingFeedback,
  BackButton,
  Select as SelectComponent,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import styles from './styles'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const ChargebackForm = ({ isEdit, chargeback }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema(isEdit),
    defaultValues: {
      orderId: chargeback?.orderId || '',
      reason: chargeback?.reason || '',
      proof: chargeback?.proof || '',
      comment: chargeback?.comment || '',
      nsuCode: chargeback?.nsuCode || '',
      occurrenceDate: chargeback?.occurrenceDate || new Date(),
      contestationDeadline: chargeback?.contestationDeadline || new Date(),
      status: chargeback?.status || '',
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.intermediador.chargebacks.edit({
          chargebackId: chargeback?.id,
          chargeback: {
            status: data?.status,
            reason: data?.reason,
            proof: data?.proof,
            comment: data?.comment,
            nsuCode: data?.nsuCode,
            occurrenceDate: data?.occurrenceDate.toString(),
            contestationDeadline: data?.contestationDeadline.toString(),
          },
        })
      } else {
        await service.intermediador.chargebacks.create({
          chargeback: {
            orderId: data?.orderId,
            reason: data?.reason,
            proof: data?.proof,
            comment: data?.comment,
            nsuCode: data?.nsuCode,
            occurrenceDate: data?.occurrenceDate.toString(),
            contestationDeadline: data?.contestationDeadline.toString(),
          },
        })
      }
      snackbar.open({
        message: `chargeback ${isEdit ? 'atualizado' : 'criado'}  com sucesso`,
        variant: 'success',
      })
      setLoading(false)
      history.push(routes.chargebacks.main)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error, true) ??
          `Falha ao ${isEdit ? 'atualizar' : 'criar'} o chargeback!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Box py={1}>
        <form id="chargebackForm" onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ gap: 20 }}>
            {!isEdit && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Token da transação"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.orderId}
                      className={classes.roundedTextField}
                      helperText={errors?.orderId?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="orderId"
                  mode="onBlur"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Motivo do chargeback"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.reason}
                    className={classes.roundedTextField}
                    helperText={errors?.reason?.message}
                    fullWidth
                  />
                }
                control={control}
                name="reason"
                mode="onBlur"
              />
            </Grid>
            {isEdit && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <SelectComponent
                      label="Status"
                      items={constants.chargebacks.SELECT_STATUS}
                      error={!!errors.status}
                      className={classes.roundedTextField}
                      helperText={errors?.status?.message}
                    />
                  }
                  control={control}
                  name="status"
                  mode="onBlur"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="URL com comprovante"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.proof}
                    className={classes.roundedTextField}
                    helperText={errors?.proof?.message}
                    fullWidth
                  />
                }
                control={control}
                name="proof"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Comentários do analista"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.comment}
                    className={classes.roundedTextField}
                    helperText={errors?.comment?.message}
                    fullWidth
                  />
                }
                control={control}
                name="comment"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Código nsu"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.nsuCode}
                    className={classes.roundedTextField}
                    helperText={errors?.nsuCode?.message}
                    fullWidth
                  />
                }
                control={control}
                name="nsuCode"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    fullWidth
                    label="Data de ocorrência"
                    format="DD/MM/yyyy"
                    className={classes.roundedTextField}
                    helperText={errors?.occurrenceDate?.message}
                    error={!!errors.occurrenceDate}
                    disableFuture
                    inputVariant="outlined"
                  />
                }
                control={control}
                name="occurrenceDate"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    fullWidth
                    label="Prazo de contestação"
                    format="DD/MM/yyyy"
                    className={classes.roundedTextField}
                    helperText={errors?.contestationDeadline?.message}
                    error={!!errors.contestationDeadline}
                    inputVariant="outlined"
                  />
                }
                control={control}
                name="contestationDeadline"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={1}>
          <BackButton
            className={classes.roundedButton}
            onClick={() => history.push(routes.chargebacks.main)}
          >
            Voltar
          </BackButton>
        </Box>
        <Box>
          <Button
            form="chargebackForm"
            color="primary"
            variant="contained"
            type="submit"
            className={classes.roundedButton}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  )
}

ChargebackForm.propTypes = {
  isEdit: PropTypes.bool,
  chargeback: PropTypes.object,
}

ChargebackForm.defaultProps = {
  isEdit: false,
}

export default ChargebackForm
