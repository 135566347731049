import PropTypes from 'prop-types'

import { useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

import { Box, Grid, Divider, makeStyles } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  Page,
  LabelView,
  BackButton,
  LoadingBox,
} from 'components'
import { OrderProductsTable, OrderPaymentsTable } from './components'
import { useHistory } from 'react-router-dom'
import helpers from 'helpers'

import * as service from 'service'
import { routes } from 'Routes'
import styles from './styles'

const useStyles = makeStyles(styles)

const OrdersShow = ({ match }) => {
  const { orderId } = match.params
  const classes = useStyles()
  const [order, setOrder] = useState()
  const history = useHistory()

  const { response, isLoading } = useFetch(
    service.intermediador.orders.get,
    {
      orderId,
    },
    [],
  )

  useEffect(() => {
    setOrder(response?.data?.order)
  }, [response])

  return (
    <Page title="Detalhes da transação">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Superfin"
          title="Transação"
          subtitle="Detalhes da transação"
        />
        <Box mt={3}>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              <Divider />
              <Box>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView label="Token" value={order?.token} />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView label="Código" value={order?.code} />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Valor"
                      value={helpers.formatters.floatToMoney(order?.amount)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView
                      label="Status"
                      value={helpers.orders.statusLabel(order?.status)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Nome do vendedor"
                      value={order?.sellerName}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <LabelView
                      label="Nome do comprador"
                      value={order?.buyer?.name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <LabelView
                      label="Data da criação"
                      value={helpers.formatters.date(order?.createdAt)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <LabelView label="Produtos" />
                    <OrderProductsTable products={order?.products} />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <LabelView label="Pagamentos" />
                    <OrderPaymentsTable
                      payments={order?.payments}
                      order={order}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box pr={1}>
                  <BackButton
                    className={classes.roundedButton}
                    onClick={() => history.push(routes.orders.main)}
                  >
                    Voltar
                  </BackButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Page>
  )
}

OrdersShow.propTypes = {
  match: PropTypes.object.isRequired,
}

export default OrdersShow
