import * as yup from 'yup'

const schema = () => {
  return yup.object().shape({
    tax: yup.string().required(),
    condition: yup.string().required(),
  })
}

export default schema
