import { Box, TablePagination, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
} from 'components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import { WithdrawsTable } from './components'
import constants from 'constants/index'
import * as service from 'service'

const WithdrawsMain = () => {
  const filter = useFilter()

  const theme = useTheme()
  const { perPage, page, handleChangePage } = usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.intermediador.withdraws.get,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <>
      <Page title="Solicitação de saque">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Solicitações de saques"
            subtitle="Solicitações de saques"
          />
          <Box mt={4} display="flex" justifyContent="end">
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
          <WithdrawsTable
            withdraws={response?.data?.withdraws ?? []}
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount ?? 0}
              onChangePage={handleChangePage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Valor" name="amount" />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={constants.withdraws.PENDING_STATUS}>
              {constants.withdraws.PENDING_LABEL_PT}
            </option>
            <option value={constants.withdraws.PROCESSED_STATUS}>
              {constants.withdraws.PROCESSED_LABEL_PT}
            </option>
            <option value={constants.withdraws.CANCELED_STATUS}>
              {constants.withdraws.CANCELED_LABEL_PT}
            </option>
          </select>
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            disableFuture
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            disableFuture
            data-target="createdAtGt"
            data-max
          />
        </Filters>
      </Page>
    </>
  )
}

export default WithdrawsMain
