import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.priceTables.IN_PROGRESS_STATUS:
      return constants.priceTables.IN_PROGRESS_LABEL
    case constants.priceTables.WAITING_APPROVAL_STATUS:
      return constants.priceTables.WAITING_APPROVAL_LABEL
    case constants.priceTables.ACTIVE_STATUS:
      return constants.priceTables.ACTIVE_LABEL
    case constants.priceTables.INACTIVE_STATUS:
      return constants.priceTables.INACTIVE_LABEL
    default:
      return ''
  }
}

export default statusLabel
