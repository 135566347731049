import { Box, TablePagination, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
} from 'components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import { StatementsTable } from './components'
import constants from 'constants/index'
import * as service from 'service'

const StatementsMain = ({ match }) => {
  const accountId = match.params.accountId
  const filter = useFilter()

  const theme = useTheme()
  const { perPage, page, handleChangePage } = usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.intermediador.statements.get,
    {
      ...{ ...filter.filters, accountId: accountId },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Extrato">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader title="Listagem" menu="Extrato" subtitle="Extrato" />
        <Box mt={4} display="flex" justifyContent="end">
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </Box>
        <StatementsTable
          statements={response?.data?.accountStatements ?? []}
          refresh={refresh}
        />
        <Box px={2} display="flex" justifyContent="flex-end">
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount ?? 0}
            onChangePage={handleChangePage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
      </Container>
      <Filters filter={filter}>
        <select textfieldinput="true" label="Tipo" name="transactionType">
          <option value=""></option>
          <option value={constants.statements.TYPES.INPUT_TYPE}>
            {constants.statements.TYPES_LABELS.INPUT_LABEL}
          </option>
          <option value={constants.statements.TYPES.OUTPUT_TYPE}>
            {constants.statements.TYPES_LABELS.OUTPUT_LABEL}
          </option>
          <option value={constants.statements.TYPES.CHARGEBACK_TYPE}>
            {constants.statements.TYPES_LABELS.CHARGEBACK_LABEL}
          </option>
        </select>
        <input
          datepickerinput="true"
          label="Data de criação inicial"
          name="createdAtGt"
          disableFuture
          data-target="createdAtLt"
          data-min
        />
        <input
          datepickerinput="true"
          label="Data de criação final"
          name="createdAtLt"
          disableFuture
          data-target="createdAtGt"
          data-max
        />
      </Filters>
    </Page>
  )
}

export default StatementsMain
