const styles = (theme) => ({
  rootNew: {
    paddingRight: theme.spacing(30),
    paddingLeft: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
})

export default styles
