import { useState } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'
import { Select } from 'components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import { COMPANY_TOKEN_LOGIN_WITH_ID } from 'service/env'
import * as service from 'service'

import styles from './styles'

const useStyles = makeStyles(styles)

const ChangeAccount = ({ open, setOpen }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { user, account, accounts, setCurrentAccount, loadDataWithAnimation } =
    useAuth()

  const [changedAccount, setAccountToken] = useState(account?.id)

  const handleSubmit = async () => {
    try {
      setOpen(false)

      if (changedAccount !== account?.id) {
        await service.intermediador.auth.login({
          companyToken: COMPANY_TOKEN_LOGIN_WITH_ID,
          token: service.intermediador.auth.getTokenLoginWIth(),
          accountToken: changedAccount,
        })

        setCurrentAccount(user, accounts)
        await loadDataWithAnimation()
      }
    } catch (err) {
      var message = 'Ocorreu um erro ao acessar a conta'
      if (err?.response?.status === 401) {
        message += ', verifique se você possui acesso à essa conta'
      }
      snackbar.open({
        variant: 'error',
        message: message,
      })
    }
  }

  const changeAccount = ({ target }) => {
    setAccountToken(target?.value)
  }

  return (
    <Dialog
      className={classes.modal}
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">Alterar conta</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.modalContent}>
        <Box my={1}>
          <Select
            defaultValue={account?.id}
            items={accounts}
            onChange={changeAccount}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleSubmit}
        >
          Alterar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeAccount
