import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core'
import { LoadingButton } from 'components'
import { X as XIcon } from 'react-feather'

import schema from './schema'
import styles from './styles'
import * as service from 'service'
import clsx from 'clsx'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

const useStyles = makeStyles(styles)

const DialogApprovePayment = ({ open, setOpen = () => {}, payment }) => {
  const history = useHistory()

  const classes = useStyles()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      reason: '',
    },
  })

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (data) => {
    try {
      await service.intermediador.payments.approve({
        paymentId: payment.token,
        reason: data.reason,
      })

      snackbar.open({
        message: 'Pagamento pago manualmente',
        variant: 'success',
      })
      setOpen(false)
      setTimeout(() => {
        history.go(0)
      }, 2000)
    } catch (error) {
      snackbar.open({
        message: 'Falha ao pagar o pagamento',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ classes: { root: classes.root } }}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Pagar manualmente</Typography>
          <IconButton onClick={handleClose}>
            <XIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form id="approvePaymentForm" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container>
            <Grid item sm={12}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    label="Motivo"
                    type="text"
                    color="primary"
                    error={!!errors.reason}
                    helperText={errors?.reason?.message}
                    className={classes.roundedTextField}
                  />
                }
                control={control}
                name="reason"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pr={1}>
            <Button
              type="button"
              variant="outlined"
              className={clsx(classes.backButton, classes.roundedButton)}
              onClick={handleClose}
            >
              Voltar
            </Button>
          </Box>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            text="Enviar"
            className={classes.roundedButton}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

DialogApprovePayment.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  payment: PropTypes.object,
}

export default DialogApprovePayment
