import theme from './theme'
import menu from './menu'
import chargebacks from './chargebacks'
import priceTables from './priceTables'
import paymentMethodPriceTables from './paymentMethodPriceTables'
import statements from './statements'
import settingOptions from './settingOptions'
import orders from './orders'
import payments from './payments'
import withdraws from './withdraws'
import reportOptions from './reportOptions'

const constants = {
  theme,
  menu,
  chargebacks,
  priceTables,
  paymentMethodPriceTables,
  statements,
  settingOptions,
  orders,
  payments,
  withdraws,
  reportOptions,
}

export default constants
