import { DollarSign as DollarSignIcon } from 'react-feather'

const ITEMS = [
  {
    title: 'Relatório financeiro',
    icon: <DollarSignIcon size={24} color="#325c81" />,
    subtitle: 'Exportação do relatório financeiro.',
    href: '/reports/financial',
  },
]

const reportOptions = {
  ITEMS,
}

export default reportOptions
