import { useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { KeyboardDatePicker } from '@material-ui/pickers'

import moment from 'moment'
import humps from 'humps'

import styles from './styles'

const useStyles = makeStyles(styles)

const Filters = ({ filter, children, name }) => {
  const classes = useStyles()
  const [values, setValues] = useState(filter.filters)
  const { register, handleSubmit } = useForm()
  const [minDates, setMinDates] = useState({})
  const [maxDates, setMaxDates] = useState({})

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    filter.setFilters(values)
    filter.setDrawerOpen(false)
  }

  const clearFilters = () => {
    children.map((field) => {
      return setValues({ [field.props.name]: '' })
    })
    filter.setFilters('')
    filter.setDrawerOpen(false)
  }

  const setDateInterval = (date, props) => {
    const formattedProps = humps.camelizeKeys(props)
    const formattedDate = moment(date).format('YYYY-MM-DD')

    if (!formattedProps.dataMin && !formattedProps.dataMax) return

    if (formattedProps.dataMin) {
      return setMinDates({
        ...minDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }

    if (formattedProps.dataMax) {
      return setMaxDates({
        ...maxDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }
  }

  return (
    <Drawer
      anchor="right"
      open={filter.drawerOpen}
      onClose={() => filter.setDrawerOpen(false)}
    >
      <Box px={2} py={4} className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" gutterBottom>
            Filtros
          </Typography>
          {children.map(
            (field, index) =>
              (field &&
                field.props.textfieldinput &&
                field.props.skip !== 'true' && (
                  <div key={index}>
                    <TextField
                      SelectProps={{ native: true }}
                      margin="dense"
                      className={classes.filterField}
                      fullWidth
                      value={values[field.props.name] || ''}
                      select={field.type === 'select'}
                      onChange={handleChange}
                      {...field.props}
                      inputProps={{
                        ref: register,
                        ...field.props.inputProps,
                      }}
                      key={index}
                    ></TextField>
                  </div>
                )) ||
              (field && field.props.datepickerinput && (
                <div key={index}>
                  <KeyboardDatePicker
                    margin="dense"
                    className={classes.filterField}
                    fullWidth
                    format="DD/MM/yyyy"
                    minDate={minDates[field.props.name]}
                    minDateMessage="A data inicial não poser ser maior que a data final"
                    maxDate={maxDates[field.props.name]}
                    maxDateMessage="A data final não pode ser menor que a data inicial"
                    value={values[field.props.name] || null}
                    onChange={(e) => {
                      setDateInterval(e, field.props)
                      setValues({
                        ...values,
                        [field.props.name]: moment(e).utc(false).format(),
                      })
                    }}
                    {...field.props}
                    inputProps={{
                      ref: register,
                      ...field.props.inputProps,
                    }}
                    key={index}
                  ></KeyboardDatePicker>
                </div>
              )),
          )}
          <Button
            className={classes.filterField}
            variant="outlined"
            color="default"
            fullWidth
            type="button"
            onClick={clearFilters}
          >
            Limpar Filtros
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.filterField}
            fullWidth
          >
            {name}
          </Button>
        </form>
      </Box>
    </Drawer>
  )
}

Filters.propTypes = {
  filter: PropTypes.string.isRequired,
  children: PropTypes.node,
  name: PropTypes.string,
}

Filters.defaultProps = {
  name: 'Filtrar',
}

export default Filters
