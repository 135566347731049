import date from './date'
import dateFromNow from './dateFromNow'
import errorMessage from './errorMessage'
import hours from './hours'
import convertBoolToBinary from './convertBoolToBinary'
import convertBinaryToBool from './convertBinaryToBool'
import convertBoolToString from './convertBoolToString'
import cpf from './cpf'
import cnpj from './cnpj'
import floatToMoney from './floatToMoney'
import document from './document'
import cnpjInput from './cnpjInput'
import cpfInput from './cpfInput'
import phone from './phone'
import cep from './cep'
import rg from './rg'
import statementType from './statementType'

const formatters = {
  convertBinaryToBool,
  convertBoolToBinary,
  date,
  dateFromNow,
  errorMessage,
  hours,
  convertBoolToString,
  cpf,
  floatToMoney,
  cnpj,
  document,
  cnpjInput,
  cpfInput,
  phone,
  cep,
  rg,
  statementType,
}

export default formatters
