const styles = (theme) => ({
  boxButton: {
    backgroundColor: '#FFF',
    borderRadius: '20px',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconBox: {
    backgroundColor: '#e3e9ec',
    borderRadius: '50%',
  },
})

export default styles
