const styles = (theme) => ({
  logo: {
    flexGrow: 1,
    width: '145px',
    height: '37px',
  },
  appBar: {
    backgroundColor: theme.palette.white,
  },
})

export default styles
