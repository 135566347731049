import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ purchaserPaymentMethodId = '', ...params }) => {
  return await intermediadorAPI.get(
    `/purchaser_payment_methods/${purchaserPaymentMethodId}`,
    { params },
  )
}

const edit = async ({ ...params }) => {
  return await intermediadorAPI.put(`/purchaser_payment_methods`, params)
}

const purchaserPaymentMethods = {
  get,
  edit,
}

export default purchaserPaymentMethods
