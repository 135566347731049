import { Container, makeStyles, Box } from '@material-ui/core'

import { ContentHeader, Page } from 'components'
import AccountsForm from './components/AccountsForm'
import useFetch from 'hooks/useFetch'

import styles from './styles'
import * as service from 'service'

const useStyles = makeStyles(styles)

const AccountsNew = () => {
  const classes = useStyles()

  const { response } = useFetch(service.intermediador.priceTables.get, {
    perPage: 10000,
    status: 'ACTIVE',
  })

  return (
    <Page title="Novo cliente">
      <Container maxWidth={false} className={classes.rootNew}>
        <ContentHeader menu="Clientes" title="Criar" subtitle="Novo cliente" />
        <Box mt={6}>
          <AccountsForm priceTables={response?.data?.priceTables} />
        </Box>
      </Container>
    </Page>
  )
}

export default AccountsNew
