const INPUT_TYPE = 'INPUT'
const OUTPUT_TYPE = 'OUTPUT'
const CHARGEBACK_TYPE = 'CHARGEBACK'

const INPUT_LABEL = 'ENTRADA'
const OUTPUT_LABEL = 'SAÍDA'
const CHARGEBACK_LABEL = 'CHARGEBACK'

const TYPES = {
  INPUT_TYPE,
  OUTPUT_TYPE,
  CHARGEBACK_TYPE,
}

const TYPES_LABELS = {
  INPUT_LABEL,
  OUTPUT_LABEL,
  CHARGEBACK_LABEL,
}

const statements = {
  TYPES,
  TYPES_LABELS,
}

export default statements
