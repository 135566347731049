const PENDING_STATUS = 1
const PROCESSED_STATUS = 2
const CANCELED_STATUS = 3
const PROCESSING_STATUS = 4

const PENDING_LABEL_PT = 'PENDENTE'
const PROCESSED_LABEL_PT = 'PROCESSADO'
const CANCELED_LABEL_PT = 'CANCELADO'
const PROCESSING_LABEL_PT = 'EM PROCESSAMENTO'

const PENDING_LABEL_EN = 'PENDING'
const PROCESSED_LABEL_EN = 'PROCESSED'
const CANCELED_LABEL_EN = 'CANCELED'
const PROCESSING_LABEL_EN = 'PROCESSING'

const withdraws = {
  PENDING_STATUS,
  PROCESSED_STATUS,
  CANCELED_STATUS,
  PROCESSING_STATUS,
  PENDING_LABEL_PT,
  PROCESSED_LABEL_PT,
  CANCELED_LABEL_PT,
  PROCESSING_LABEL_PT,
  PENDING_LABEL_EN,
  PROCESSED_LABEL_EN,
  CANCELED_LABEL_EN,
  PROCESSING_LABEL_EN,
}

export default withdraws
