import api from 'service/api'

const intermediadorAPI = api.create('intermediador', true)

const get = async ({ purchaserId = '', ...params }) => {
  return await intermediadorAPI.get(`/purchasers`, { params })
}

const purchasers = {
  get,
}

export default purchasers
