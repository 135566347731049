import constants from 'constants/index'

const statementType = (type) => {
  switch (type) {
    case constants.statements.TYPES.INPUT_TYPE:
      return constants.statements.TYPES_LABELS.INPUT_LABEL
    case constants.statements.TYPES.OUTPUT_TYPE:
      return constants.statements.TYPES_LABELS.OUTPUT_LABEL
    case constants.statements.TYPES.CHARGEBACK_TYPE:
      return constants.statements.TYPES_LABELS.CHARGEBACK_LABEL
    default:
      return ''
  }
}

export default statementType
