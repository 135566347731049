import PropTypes from 'prop-types'

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  makeStyles,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core'
import { LoadingButton } from 'components'
import { X as XIcon } from 'react-feather'

import styles from './styles'
import * as service from 'service'
import clsx from 'clsx'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const DialogApprovePayment = ({ open, setOpen = () => {}, withdrawToken }) => {
  const history = useHistory()

  const classes = useStyles()
  const snackbar = useSnackbar()

  const handleClose = () => {
    setOpen(false)
  }

  const processWithdraw = async () => {
    try {
      await service.intermediador.withdraws.processWithdraw({
        token: withdrawToken,
      })

      snackbar.open({
        message: 'Saque processado com sucesso',
        variant: 'success',
      })
      setOpen(false)
      setTimeout(() => {
        history.go(0)
      }, 2000)
    } catch (error) {
      snackbar.open({
        message: 'Falha ao processar o saque',
        variant: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ classes: { root: classes.root } }}
      onClose={handleClose}
      maxWidth="xs"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Processar saque?</Typography>
          <IconButton onClick={handleClose}>
            <XIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogActions>
        <Box pr={1}>
          <Button
            type="button"
            variant="outlined"
            className={clsx(classes.backButton, classes.roundedButton)}
            onClick={handleClose}
          >
            Voltar
          </Button>
        </Box>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          text="Enviar"
          onClick={() => processWithdraw()}
          className={classes.roundedButton}
        />
      </DialogActions>
    </Dialog>
  )
}

DialogApprovePayment.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  withdrawToken: PropTypes.string,
}

export default DialogApprovePayment
