import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box, makeStyles } from '@material-ui/core'

import OrdersTableRow from '../OrdersTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import styles from './styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const OrdersTable = ({ orders, refresh }) => {
  const classes = useStyles()

  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table
          size="medium"
          noWrap
          className={classes.table}
          emptyMessage="Nenhuma transação encontrada"
        >
          <TableHead>
            <TableRow>
              <TableCell width="20%" align="left">
                Nome do vendedor
              </TableCell>
              <TableCell width="20%" align="left">
                Nome do comprador
              </TableCell>
              <TableCell width="10%" align="left">
                Valor
              </TableCell>
              <TableCell width="20%" align="left">
                Status
              </TableCell>
              <TableCell width="20%" align="left">
                Data de criação
              </TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <OrdersTableRow
                order={order}
                refresh={refresh}
                key={order.token}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

OrdersTable.defaultProps = {
  refresh: () => {},
}

export default OrdersTable
