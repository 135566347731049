import { Container, Page, ContentHeader } from 'components'
import { Box, makeStyles, Grid } from '@material-ui/core'
import { SettingsIcon } from './components'

import styles from './styles'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const SettingsMain = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Configurações">
      <Container maxWidth={false}>
        <Box mb={6}>
          <ContentHeader
            title="Configurações"
            menu="Configurações"
            subtitle="Configurações"
          />
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            {constants.settingOptions.ITEMS &&
              constants.settingOptions.ITEMS.map((item) => (
                <SettingsIcon
                  key={item.title}
                  icon={item.icon}
                  title={item.title}
                  subtitle={item.subtitle}
                  href={item.href}
                />
              ))}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default SettingsMain
