import PropTypes from 'prop-types'

import { ChevronRight as ChevronRightIcon } from 'react-feather'
import { Box, Typography, makeStyles } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import styles from './styles'
import theme from 'theme'

const useStyles = makeStyles(styles)

const ReportsList = ({ icon, title, subtitle, href }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box
      p={2}
      mb={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.boxButton}
      onClick={() => history.push(href)}
    >
      <Box display="flex" alignItems="center">
        <Box
          p={2}
          mr={2}
          display="flex"
          alignItems="center"
          className={classes.iconBox}
        >
          {icon}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography variant="h5" color="textPrimary">
              {title}
            </Typography>
          </Box>
          <Typography variant="body1" color="textPrimary">
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <ChevronRightIcon size={28} color={theme.palette.text.primary} />
      </Box>
    </Box>
  )
}

ReportsList.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default ReportsList
